import React from "react";
import { withRouter } from "react-router-dom";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true });
    console.error(error);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div id="notfound">
          <div className="notfound">
            <div>
              <div className="notfound-404">
                <h1>!</h1>
              </div>
              <h2>Erreur</h2>
            </div>
            <p>
              Aie Aie Aie ; une erreur s'est produite...
              <button
                onClick={() => {
                  this.props.history.push("/login");
                  window.location.reload();
                }}
                className="Btn returnBtn"
              >
                RETOURNER A LA PAGE PRINCIPALE
              </button>
            </p>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}
export default withRouter(ErrorBoundary);
