import React, { useState, useEffect, useCallback } from "react";
import "./statsSections.scss";
import StatsEstablishmentsListItem from "./StatsEstablishmentsListItem";
import StatsMonthsList from "./StatsMonthsList";
import StatsTable from "./StatsTable";
import { getEstablishmentsList } from "../../actions/establishment";
import Pagination from "../layout/Pagination";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const StatisticsSection = ({
  getEstablishmentsList,
  establishments,
  loading,
}) => {
  const LIMIT_SIZE = 5;

  const [selectedElement, changeSelectedElement] = useState();
  const [displayTable, changeDisplayTable] = useState(false);
  const [statsData, changeStatsData] = useState();
  const [pageNumber, changePageNumber] = useState(1);
  const [establishmentData, changeEstablishmentData] = useState(1);

  useEffect(() => {
    getEstablishmentsList(pageNumber, LIMIT_SIZE);
  }, [getEstablishmentsList, pageNumber]);

  const displayEstablishmentData = useCallback((data) => {
    changeSelectedElement(data.id);
    changeEstablishmentData({
      name: data.name,
      type: data.type,
      postalCode: data.postalCode,
      userGender: data.userGender,
      userFirstName: data.userFirstName,
      userLastName: data.userLastName,
      email: data.email,
      isArchived: data.isArchived,
      code: data.code,
      id: data.id,
    });
  }, []);
  useEffect(() => {
    if (!loading && establishments.items && establishments.items.length > 0) {
      displayEstablishmentData(establishments.items[0]);
    }
  }, [loading, displayEstablishmentData, establishments.items]);

  const onChangePageNumber = (number) => {
    changePageNumber(number);
  };
  const displayStatsTable = (data) => {
    changeDisplayTable(true);
    changeStatsData(data)
  };


  return displayTable ? (
    <StatsTable changeDisplayTable={changeDisplayTable} establishmentData={establishmentData} statsData={statsData}/>
  ) : (
    <div className="adminsSectiondMainContainer">
      <div className="adminsListContainer">
        {establishments &&
          establishments.items &&
          establishments.items.map((establishment) => (
            <StatsEstablishmentsListItem
              key={establishment.id}
              displayEstablishmentData={displayEstablishmentData}
              establishmentData={establishment}
              isSelected={selectedElement === establishment.id}
            />
          ))}
        <Pagination
          totalPages={
            establishments && establishments.meta
              ? establishments.meta.totalPages
              : 1
          }
          page_number={pageNumber}
          onChangePageNumber={onChangePageNumber}
        />
      </div>
      <div className="adminsFormContainer">
        <StatsMonthsList
          displayStatsTable={displayStatsTable}
          establishmentData={establishmentData}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  establishments: state.establishments.establishments,
  loading: state.establishments.loading,
});

StatisticsSection.propTypes = {
  getEstablishmentsList: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  getEstablishmentsList,
})(StatisticsSection);
