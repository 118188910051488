import React from "react";
import { withRouter } from "react-router-dom";
import placeHolder from "../../assets/images/user-placeholder.png";
const AdminsListItem = ({ displayStudentData, studentData, isSelected }) => {
  return (
    <div
      onClick={() => displayStudentData(studentData)}
      className={
        isSelected
          ? "adminListitemMainContainer-active"
          : "adminListitemMainContainer"
      }
    >
      <div style={{
        width:"25rem"
      }} className="adminListitemTextContainer">
        <p className="adminListitemName">
          {studentData.firstName + " " + studentData.lastName}
        </p>
        <p className="adminListitemRole">{studentData.email}</p>
      </div>
      <div style={{
        width:"15rem"
      }}>
        <p className="adminsState" style={{
          background:studentData.isActive ? "#28a745" : "#dc3545" ,
          color:"#fff"
        }} className="adminsState">
          {studentData.isActive ? "Activé" : "Suspendu"}
        </p>
        <p className="adminsState" style={{
          background:studentData.hasConfirmedAccount ? "#28a745" : "#9b9b9b" ,
          color:"#fff"
        }} >
          {studentData.hasConfirmedAccount ? "Compte confirmé" : "En attente de confirmation"}
        </p>
      </div>
    </div>
  );
};

export default withRouter(AdminsListItem);
