import axios from "axios";

import { POSTS_LIST, POSTS_ERROR, POST_UPLOAD_FILE, ADD_POST, UPDATE_POST } from "./types";
import { setAlert } from "./alert";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

// Get posts list
export const getPostsList = (page, limit) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(
      `${BASE_URL}/posts/?page=${page}&limit=${limit}`,
      config
    );

    dispatch({
      type: POSTS_LIST,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: POSTS_ERROR,
      payload: err.response.data.message,
    });
    dispatch(
      setAlert(`Echec de l'envoi: ${err.response.data.message}`, "danger")
    );
  }
};

// File upload
export const uploadFiles = (formInfos, file) => async (dispatch) => {
  let formData = new FormData();
  formData.append("file", file);
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  try {
    const res = await axios.post(`${BASE_URL}/upload`, formData, config);
    dispatch({
      type: POST_UPLOAD_FILE,
      payload: res.data,
    });
    if (formInfos.id) {
      dispatch(updatePost(formInfos, res.data));
    } else {
      dispatch(addPost(formInfos, res.data));
    }
    dispatch(setAlert("Post ajouté", "success"));
  } catch (err) {
    dispatch({
      type: POSTS_ERROR,
      payload: err.response.data.message,
    });
    dispatch(
      setAlert(`Echec de l'envoi: ${err.response.data.message}`, "danger")
    );
  }
};

// Add post
export const addPost = (formData, imgData) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  if (imgData) {
    formData["mediaName"] = imgData.filename;
  }

  const body = JSON.stringify(formData);

  try {
    const res = await axios.post(`${BASE_URL}/posts`, body, config);

    dispatch({
      type: ADD_POST,
      payload: res.data,
    });
    dispatch(setAlert("Post ajouté", "success"));
  } catch (err) {
    dispatch({
      type: POSTS_ERROR,
      payload: err.response.data.message,
    });
    dispatch(
      setAlert(`Echec de l'envoi: ${err.response.data.message}`, "danger")
    );
  }
};

// Update post
export const updatePost = (formData, imgData) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  if (imgData) {
    formData["mediaName"] = imgData.filename;
  }

  const body = JSON.stringify(formData);

  try {
    const res = await axios.patch(
      `${BASE_URL}/posts/${formData.id}`,
      body,
      config
    );

    dispatch({
      type: UPDATE_POST,
      payload: res.data,
    });

    dispatch(setAlert("le poste à été mis à jour", "success"));
  } catch (err) {
    dispatch({
      type: POSTS_ERROR,
      payload: err.response.data.message,
    });
    dispatch(
      setAlert(`Echec de l'envoi: ${err.response.data.message}`, "danger")
    );
  }
};
