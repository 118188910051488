import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import validateEmail from "../../utils/validateMail";

import {
  createEstablishment,
  updateEstablishment,
} from "../../actions/establishment";

const EstablishmentForm = ({
  createEstablishment,
  updateEstablishment,
  establishmentData,
}) => {
  const [formData, setFormData] = useState({
    name: "",
    type: "college",
    postalCode: "",
    userGender: "M.",
    userFirstName: "",
    userLastName: "",
    email: "",
    code: "",
    isArchived: false,
    id: null,
  });

  useEffect(() => {
    setFormData({
      name: establishmentData ? establishmentData.name : "",
      type: establishmentData ? establishmentData.type : "college",
      postalCode: establishmentData ? establishmentData.postalCode : "",
      userGender: establishmentData ? establishmentData.userGender : "M.",
      userFirstName: establishmentData ? establishmentData.userFirstName : "",
      userLastName: establishmentData ? establishmentData.userLastName : "",
      isArchived: establishmentData ? establishmentData.isArchived : false,
      email: establishmentData ? establishmentData.email : "",
      code: establishmentData ? establishmentData.code : "",
      id: establishmentData ? establishmentData.id : null,
    });
  }, [establishmentData]);

  const [error, setError] = useState(null);

  const {
    name,
    type,
    postalCode,
    userGender,
    userFirstName,
    userLastName,
    isArchived,
    email,
    code,
    id,
  } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setError(null);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (name.length === 0) {
      setError("Le nom de l'établissement est obligatoire");
      return;
    }
    if (postalCode.length === 0) {
      setError("Le code postal est obligatoire");
      return;
    }
    if (userFirstName.length === 0) {
      setError("Le prénom est obligatoire");
      return;
    }
    if (userLastName.length === 0) {
      setError("Le nom est obligatoire");
      return;
    }
    const validateMail = validateEmail(email);
    if (!validateMail) {
      setError("Format e-mail invalide");
      return;
    }
    if (id) {
      updateEstablishment(formData);
    } else {
      createEstablishment(formData);
      setFormData({
        name: "",
        type: "college",
        postalCode: "",
        userGender: "M.",
        userFirstName: "",
        userLastName: "",
        email: "",
        isArchived: false,
        id: id,
      });
    }
  };

  const submitArchiveEstablishment = (e) => {
    e.preventDefault();
    updateEstablishment({ id, isArchived: !isArchived });
    setFormData({
      ...formData,
      isArchived: !isArchived,
    });
  };

  return (
    <>
      <p className="addAdminFormTitle">
        {id ? "Etablissement" : "Nouvel établissement"}
      </p>
      <div className="formSection">
        <input
          type="text"
          className="form-control"
          name="name"
          placeholder="Nom de l'établissement"
          value={name}
          onChange={(e) => onChange(e)}
        />
        <select name="type" value={type} onChange={(e) => onChange(e)}>
          <option value="college">Collège</option>
          <option value="high_school">Lycée</option>
        </select>
        <input
          type="text"
          className="form-control"
          name="postalCode"
          placeholder="Code postal"
          value={postalCode}
          onChange={(e) => onChange(e)}
        />
        <div className="establishmentMultiLineForm">
          <select
            name="userGender"
            value={userGender}
            onChange={(e) => onChange(e)}
            className="establishmentGenderSelect"
          >
            <option value="M.">M.</option>
            <option value="Mme.">Mme.</option>
          </select>
          <input
            type="text"
            className="establishmentMultiLineForm--input"
            name="userFirstName"
            placeholder="Prénom du représentant"
            value={userFirstName}
            onChange={(e) => onChange(e)}
          />
          <input
            type="text"
            className="establishmentMultiLineForm--input"
            name="userLastName"
            placeholder="Nom du représentant"
            value={userLastName}
            onChange={(e) => onChange(e)}
          />
        </div>
        <input
          type="email"
          className="form-control"
          name="email"
          placeholder="Email du représentant"
          value={email}
          onChange={(e) => onChange(e)}
        />
        {code && (
          <input
            disabled
            type="text"
            className="form-control"
            name="code"
            value={code}
          />
        )}

        <p className="error-msg">{error}</p>
      </div>

      <div className="adminsFormBtnContainer">
        {id ? (
          <>
            <button
              onClick={submitArchiveEstablishment}
              className="Btn returnBtn"
            >
              {isArchived ? "Reactiver" : "Archiver"}
            </button>
            <button onClick={onSubmit} className="Btn">
              Modifier
            </button>
          </>
        ) : (
          <button onClick={onSubmit} className="Btn">
            Générer code et l'invitation
          </button>
        )}
      </div>
    </>
  );
};
EstablishmentForm.propTypes = {
  createEstablishment: PropTypes.func.isRequired,
  updateEstablishment: PropTypes.func.isRequired,
};

export default connect(null, {
  createEstablishment,
  updateEstablishment,
})(EstablishmentForm);
