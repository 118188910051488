import React, { useState, useEffect, useRef } from "react";
import UserBubble from "./UserBubble";

const QrSimulatorItem = ({ quickReplies, questionsHandler, reloadState }) => {
  const [qrState, setQrState] = useState(false);
  const [qrtext, setQrText] = useState("");

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [quickReplies]);

  const addQuestion = (data, content) => {
    setQrState(true);
    setQrText(content);
    questionsHandler(data);
  };

  useEffect(() => {
    setQrState(false);
  }, [reloadState]);

  return (
    <div className="QrSimulatorItemContainer">
      {!qrState &&
        quickReplies &&
        quickReplies.length > 0 &&
        quickReplies.map((qr, index) => (
          <p
            ref={messagesEndRef}
            key={index}
            onClick={() => addQuestion(qr.nextQuestion.id, qr.content)}
            className="QrSimulatorItemText"
          >
            {qr.content}
          </p>
        ))}
      {qrState && <UserBubble qrtext={qrtext} />}
    </div>
  );
};
export default QrSimulatorItem;
