import React from "react";
import SpeachLogo from "../../../assets/images/avatarSpeach.svg";
import QrSimulatorItem from "./QrSimulatorItem";

const SpeachBubble = ({ questionData, questionsHandler, reloadState }) => {
  const SEPARATOR = "$$@SPEACH@$$";

  const questionsBubbles = questionData.content.split(SEPARATOR);
  return (
    <>
      {questionsBubbles.map((question, index) => {
        if (question.length > 0)
          return (
            <div key={index} className="speachBubbleContainer">
              <img
                className="speachBubbleImage"
                src={SpeachLogo}
                alt="speachBubbleImage"
              />
              <div>
                {question.includes('api/download') || question.startsWith("http") && question.endsWith(".gif") ? (
                  <img
                    className="QuestionImgPreviewSimulator"
                    src={question}
                    alt="uploaded img"
                  />
                ) : (
                  <p className="speachBubbleText">{question}</p>
                )}
              </div>
            </div>
          );
      })}

      <QrSimulatorItem
        reloadState={reloadState}
        questionsHandler={questionsHandler}
        quickReplies={questionData.quickReplies}
      />
    </>
  );
};
export default SpeachBubble;
