import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { login } from "../../actions/auth";
import "./auth.scss";
import SpeachLogo from "../../assets/images/avatarSpeach.svg";
import showPassword from "../../assets/images/ic-visibility.png";
import hidePassword from "../../assets/images/ic-unvisibility.png";
import validateEmail from "../../utils/validateMail";

const Login = ({ login, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [passwordState, SetPasswordState] = useState(true);

  const { email, password } = formData;
  const [error, setError] = useState(null);

  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  const onChange = (e) => {
    setError(null);
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const changePasswordState = () => SetPasswordState(!passwordState);

  const onSubmit = (e) => {
    const validateMail = validateEmail(email);
    if (!validateMail) {
      setError("Format e-mail invalide");
      return;
    }
    e.preventDefault();
    login(email, password);
  };

  return (
    <div className="mainContainer">
      <div className="titleSection">
        <img className="mainImage" alt="speach logo" src={SpeachLogo} />
        <p className="mainTitle">Hello Speach</p>
      </div>
      <div className="formSection">
        <input
          type="email"
          className="form-control"
          name="email"
          placeholder="Email"
          value={email}
          onChange={(e) => onChange(e)}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              onSubmit(e);
            }
          }}
        />
        <div className="InputPasswordContainer">
          <input
            type={passwordState ? "password" : "text"}
            className="form-control"
            name="password"
            placeholder="Mot de passe"
            value={password}
            onChange={(e) => onChange(e)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                onSubmit(e);
              }
            }}
          />
          <img
            onClick={changePasswordState}
            className="passwordImg"
            src={passwordState ? showPassword : hidePassword}
            alt="showPassword"
          />
        </div>
        <p className="error-msg">{error}</p>

        <Link to="/forgotten-password" className="forgotPasswordText">
          {" "}
          &gt; Mot de passe oublié ?
        </Link>
      </div>
      <button className="Btn" onClick={onSubmit}>
        Se connecter
      </button>
    </div>
  );
};

Login.propTypes = {
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  history: PropTypes.any,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { login })(Login);
