import React, { useEffect, useState } from "react";
import DashboardItem from "./DashboardItem";
import { connect } from "react-redux";
import { getAcademicYearList } from "../../actions/academic";

const Dashboard = ({ history, user, academicYears, getAcademicYearList }) => {
  const [currentYear, setCurrentYear] = useState([]);

  useEffect(() => {
    getAcademicYearList(1, 100);
  }, []);

  useEffect(() => {
    if (academicYears && academicYears.length > 0)
      setCurrentYear(academicYears.filter((year) => year.isCurrent));
  }, [academicYears]);

  return (
    <div className="dashboardMainContainer">
      <DashboardItem
        mainText={`Programme ${currentYear.length > 0 && currentYear[0].name}`}
        secondryText={"Gestion de contenus"}
        btnText={"Gérer les posts et discussions"}
        history={history}
        link={"/program"}
      />
      <DashboardItem
        mainText={"Stories permanentes et temporaires"}
        secondryText={"Gestion de contenus"}
        btnText={"Gérer les stories"}
        history={history}
        link={"/stories"}
      />
      <DashboardItem
        mainText={"Boites à questions"}
        secondryText={"Gestion des boites à questions"}
        btnText={"Gérer les boites à questions"}
        history={history}
        link={"/question-box"}
      />
      {user && user.role === "super_admin" && (
        <DashboardItem
          mainText={"Administrateurs Speach"}
          secondryText={"Gestion des comptes"}
          btnText={"Gérer les admins"}
          history={history}
          link={"/admins"}
        />
      )}
      {user && user.role === "super_admin" && (
        <DashboardItem
          mainText={"Création d’établissement"}
          secondryText={"Gestion des comptes"}
          btnText={"Créer un numéro"}
          history={history}
          link={"/establishment"}
        />
      )}
      {user && user.role === "super_admin" && (
        <DashboardItem
          mainText={"Statistique mensuel"}
          secondryText={"Rapport mensuel de statistiques"}
          btnText={"Accéder au statistiques"}
          history={history}
          link={"/statistics"}
        />
      )}
      <DashboardItem
        mainText={"Liste des élèves"}
        secondryText={"Gestion des élèves"}
        btnText={"Afficher la liste"}
        history={history}
        link={"/students"}
      />
      {user && user.role === "super_admin" && (
        <DashboardItem
          mainText={"Création et gestion des années scolaire"}
          secondryText={"Gestion des années scolaire"}
          btnText={"Gérer les années scolaire"}
          history={history}
          link={"/academic-year"}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  academicYears: state.academicYears.academicYears.items,
});
export default connect(mapStateToProps, { getAcademicYearList })(Dashboard);
