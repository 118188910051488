import React from "react";

const StatsMonthsListItem = ({
  month,
  selectedYear,
  displayStatsTable,
  index,
}) => {
  const displayTable = () => {
    displayStatsTable({ month, index, selectedYear });
  };
  return (
    <div onClick={displayTable} className="statsMonthsList">
      <div className="statsItemContainer">
        <p className="statsItemText">{month + " " + selectedYear}</p>
        <button onClick={displayTable} className="Btn">
          Afficher
        </button>
      </div>
    </div>
  );
};

export default StatsMonthsListItem;
