import React from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import Calendar from "../../assets/images/calendar.svg";
import BiologicIcon from "../../assets/images/bio-sujet@2x.png";
import SocialIcon from "../../assets/images/social-sujet@2x.png";
import PsychoIcon from "../../assets/images/psycho-sujet@2x.png";
import SponsorIcon from "../../assets/images/sponsor-sujet@2x.png";
import IntroIcon from "../../assets/images/intro-sujet@2x.png"
const PostsListItem = ({ displayPostData, postData, isSelected }) => {
  return (
    <div
      onClick={() => displayPostData(postData)}
      className={
        isSelected
          ? "adminListitemMainContainer-active"
          : "adminListitemMainContainer"
      }
    >
      <div className="adminListitemTextContainer" >
        <p className="adminListitemName">Speach</p>
        <div className="programListitemDateContainer">
          <p className="programListitemDateText">
            {moment(postData.dateOfPublish).utc().format("DD-MM-YYYY HH:mm")}
          </p>
          <img className="programListitemImage" src={Calendar} alt="admin" />
        </div>
        <p className="programListitemText">{postData.content}</p>
        {postData.mediaName && <img
          className="programListitemBodyImg"
          src={postData.mediaName}
          alt="*"
        />}
      </div>
      <div className="programListitemTypeContainer">
        <p className="programListitemTypeText">
          {" "}
          {postData.type === "bio"
            ? "Biologique"
            : postData.type === "social"
            ? "Social"
            : postData.type === "psycho"
            ? "Psycho-affectif"
            : postData.type === "sponsor"
            ? "Sponsorisé"
            : "Présentation"}
        </p>
        <img
          className="programListitemImage"
          src={
            postData.type === "bio"
              ? BiologicIcon
              : postData.type === "social"
              ? SocialIcon
              : postData.type === "psycho"
              ? PsychoIcon
              : postData.type === "sponsor"
              ? SponsorIcon
              : IntroIcon
          }
          alt="admin"
        />
      </div>     
    </div>
  );
};

export default withRouter(PostsListItem);
