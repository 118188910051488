import React, { useState, useCallback, useEffect } from "react";
import { connect } from "react-redux";
import ConversationQR from "./ConversationQR";
import { v4 as uuidv4 } from "uuid";
import { uploadFiles } from "../../actions/story";

const ConversationBuble = ({
  name,
  data,
  quickReplies,
  questionsHandler,
  quickReplyHandler,
  questionList,
  handleQuestionsBubbles,
  conversationsList,
  disableForm
}) => {
  const [bubbles, setBubbles] = useState([]);
  const [conversationState, setConversationState] = useState([]);


  const deleteBubble = useCallback((name, id) => {
    quickReplyHandler({ id, name, value: "deleted" });
    setBubbles((oldBubbles) =>
      oldBubbles.filter((item) => {
        if (id) {
          return item.props.id !== id;
        }
        return item.props.qrname !== name;
      })
    );
  }, []);
  const addBubbles = useCallback(() => {
    quickReplyHandler({
      name: uuidv4(),
      content: "",
      id: null,
      next: "",
    });
  }, [quickReplyHandler]);

  const [formData, setFormData] = useState({
    content: "",
    id: null,
  });
  const { id } = formData;

  useEffect(() => {
    if (!data.isRoot) {
      setConversationState(() =>
        conversationsList.filter((item) => {
          return item.id !== id;
        })
      );
    } else {
      setConversationState(conversationsList);
    }
    setFormData({
      content: data ? data.content : "",
      id: data ? data.id : null,
    });
  }, [data, id]);

  const onChange = (e, index) => {
    const { value } = e.target;
    setFormData({ ...formData, [e.target.name]: value });
    questionsHandler({ name, content: value, id, level: 1 }, index);
  };

  const onChangePicture = (e, index) => {
    if (e.target.files[0]) {
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        questionsHandler(
          { name, content: reader.result, id, level: 1 },
          index,
          { index, file: e.target.files[0] }
        );
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <>
      {questionList &&
        questionList.map((question, index) => (
          <div className="questionListContainer" key={index}>
            {question.includes("data:image") ||
            question.includes("api/download") ? (
              <div>
                <img
                  className="QuestionImgPreviewimg"
                  src={question}
                  alt="uploaded img"
                />
              </div>
            ) : (
              <textarea
                type="text"
                className="discutionElement"
                name="content"
                value={question}
                onChange={(e) => onChange(e, index)}
              />
            )}

            <div className="QrBubblesOptions">
              {!disableForm && questionList.length > 1 && (
                <p
                  className="deleteDiscutionQRElement"
                  onClick={() => handleQuestionsBubbles(index)}
                >
                  Supprimer
                </p>
              )}

              <label
                htmlFor={`QrBubblesUpload${index}`}
                className="QrBubblesUploadLabel"
              >
                <p>Charger une image</p>
              </label>
              <input
                className="QrBubblesUpload"
                id={`QrBubblesUpload${index}`}
                type="file"
                onChange={(e) => onChangePicture(e, index)}
              />
            </div>
          </div>
        ))}

      <div className="QrBubblesContainer">
        {quickReplies &&
          quickReplies.length > 0 &&
          quickReplies.map((qr, index) => (
            <ConversationQR
              disableForm={disableForm}
              key={index}
              qrname={qr.name}
              parent={name}
              qrData={qr}
              deleteBubble={deleteBubble}
              quickReplyHandler={quickReplyHandler}
            />
          ))}
        {!disableForm &&
          conversationState.length > 1 &&
          quickReplies.length < 6 && (
            <p onClick={addBubbles} className="addQRBtn">
              + Nouveau QR
            </p>
          )}
      </div>
    </>
  );
};
export default connect(null, { uploadFiles })(ConversationBuble);
