import React, { useState, useEffect } from "react";
import { getStatistics } from "../../actions/statistics";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ReactExport from "react-data-export";
import moment from "moment";

const StatisticsSection = ({
  establishmentData,
  statsData,
  getStatistics,
  statistics,
  loading,
  changeDisplayTable,
}) => {
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  useEffect(() => {
    getStatistics(
      establishmentData.id,
      statsData.selectedYear,
      statsData.index
    );
  }, [getStatistics, statsData, establishmentData]);

  const [stats, changeStatsData] = useState({});
  const [postsData, changePostsData] = useState([]);

  useEffect(() => {
    if (!loading && statistics) {
      changeStatsData(statistics);
    }
  }, [loading, statistics, statsData, establishmentData]);

  useEffect(() => {
    if (stats.posts) {
      let result = Object.keys(stats.posts).map((key) => ({
        id: Number(key),
        post: stats.posts[key],
      }));
      changePostsData(result);
    }
  }, [stats]);

  return (
    <>
      <div className="statsTableDescription">
        <p>
          Statistiques {statsData.month} {statsData.selectedYear} -{" "}
          {establishmentData.name} [ {establishmentData.code} ]
        </p>
      </div>
      <table className="responstable">
        <tr>
          <th>Comptes créés</th>
          <th>Elèves connectés</th>
          <th>Total connexions</th>
          <th>Moyenne connexion</th>
          {postsData.map((post) => (
            <th key={post.id}>
              Post {post.id}({post.post.dateOfPublish})
            </th>
          ))}
          <th>Boite à questions</th>
        </tr>
        <tr>
          <td>{stats.createdAccountsNumber}</td>
          <td>{stats.uniqueConnectionsNumber}</td>
          <td>{stats.totalConnectionsNumber}</td>
          <td>{stats.avergeConnectionsNumber}</td>
          {postsData.map((post) => (
            <td key={post.id}>
              <th>
                Discussions engagées ({post.post.engagedConversationsNumber})
              </th>
              <th>
                Discussions finies ({post.post.finishedConversationsNumber})
              </th>
            </td>
          ))}
          <td>{stats.recievedQuestionsNumber}</td>
        </tr>
      </table>
      <div className="downloadContainer">
        <button
          onClick={() => changeDisplayTable(false)}
          className="Btn returnBtn"
        >
          Retour
        </button>
        <ExcelFile
          filename={`Speach statistiques ${moment().format()}`}
          element={<button className="Btn">Télécharger</button>}
        >
          <ExcelSheet data={[stats]} name="speach statistiques">
            <ExcelColumn label="Comptes créés" value="createdAccountsNumber" />
            <ExcelColumn
              label="Elèves connectés"
              value="uniqueConnectionsNumber"
            />
            <ExcelColumn
              label="Total connexions"
              value="totalConnectionsNumber"
            />
            <ExcelColumn
              label="Moyenne connexion"
              value="avergeConnectionsNumber"
            />
            <ExcelColumn
              label="Boite à questions"
              value="recievedQuestionsNumber"
            />
          </ExcelSheet>
          <ExcelSheet data={postsData} name="speach posts">
            <ExcelColumn label="post" value={(col) => `post${col.id}`} />
            <ExcelColumn
              label="Discussions engagées "
              value={(col) => col.post.engagedConversationsNumber}
            />
            <ExcelColumn
              label="Discussions finies"
              value={(col) => col.post.finishedConversationsNumber}
            />
            <ExcelColumn
              label="Date de publication"
              value={(col) => col.post.dateOfPublish}
            />
          </ExcelSheet>
        </ExcelFile>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  statistics: state.statistics.stats,
  loading: state.statistics.loading,
});

StatisticsSection.propTypes = {
  getStatistics: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { getStatistics })(StatisticsSection);
