export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const FORGET_PASSWORD_ERROR = "FORGET_PASSWORD_ERROR";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const USER_ERROR = "USER_ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";

//establishment
export const CREATE_ESTABLISHMENT = "CREATE_ESTABLISHMENT";
export const ESTABLISHMENT_ERROR = "ESTABLISHMENT_ERROR";
export const ESTABLISHMENTS_LIST = "ESTABLISHMENTS_LIST";
export const UPDATE_ESTABLISHMENT = "UPDATE_ESTABLISHMENT";
export const FULL_ESTABLISHMENTS_LIST = "FULL_ESTABLISHMENTS_LIST";

//admins
export const INVITE_ADMIN = "INVITE_ADMIN";
export const ADMIN_ERROR = "ADMIN_ERROR";
export const ADMINS_LIST = "ADMINS_LIST";
export const UPDATE_ADMIN = "UPDATE_ADMIN";
export const ARCHIVE_ADMIN = "ARCHIVE_ADMIN";

//academic year
export const ACADEMIC_LIST = "ACADEMIC_LIST";
export const ACADEMIC_ERROR = "ACADEMIC_ERROR";
export const ADD_ACADEMIC_YEAR = "ADD_ACADEMIC_YEAR";
export const UPDATE_ACADEMIC_YEAR = "UPDATE_ACADEMIC_YEAR";

//posts
export const POSTS_LIST = "POSTS_LIST";
export const POSTS_ERROR = "POSTS_ERROR";
export const ADD_POST = "ADD_POST";
export const UPDATE_POST = "UPDATE_POST";
export const POST_UPLOAD_FILE = "POST_UPLOAD_FILE"

//alerts
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

//conversations
export const CREATE_QUESTION = "CREATE_QUESTION";
export const CREATE_QR = "CREATE_QR";
export const CONVERSATION_ERROR = "CONVERSATION_ERROR";
export const QUESTIONS_LIST = "QUESTIONS_LIST";
export const UPDATE_QUESTION = "UPDATE_QUESTION";
export const DELETE_QUESTION = "DELETE_QUESTION";
export const QUESTION_UPLOAD_FILE = "QUESTION_UPLOAD_FILE";
export const CLEAR_UPLOAD_FILE = "CLEAR_UPLOAD_FILE";

//students
export const STUDENTS_ERROR = "STUDENTS_ERROR";
export const STUDENTS_LIST = "STUDENTS_LIST";
export const UPDATE_STUDENT = "UPDATE_STUDENT";
export const ARCHIVE_STUDENT = "ARCHIVE_STUDENT";

//stats
export const STATS_LIST = "STATS_LIST";
export const STATS_ERROR = "STATS_ERROR";

//stories
export const UPLOAD_FILE = "UPLOAD_FILE";
export const ADD_STORY = "ADD_STORY";
export const STORIES_LIST = "STORIES_LIST";
export const UPDATE_STORY = "UPDATE_STORY";
export const DELETE_STORY = "DELETE_STORY";
export const STORY_ERROR = "STORY_ERROR";

//question box
export const QUESTION_BOX_ERROR = "QUESTION_BOX_ERROR";
export const QUESTION_BOX_LIST = "QUESTION_BOX_LIST";
export const UPDATE_QUESTION_BOX = "UPDATE_QUESTION_BOX";
export const QUESTION_BOX_ALL_LIST = "QUESTION_BOX_ALL_LIST";
