import {
  POSTS_ERROR,
  POSTS_LIST,
  ADD_POST,
  UPDATE_POST,
} from "../actions/types";

const initialState = {
  loading: true,
  post: null,
  posts: [],
  error: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADD_POST:
      return {
        ...state,
        posts: {
          ...state.posts,
          items: [payload, ...state.posts.items],
        },
        loading: false,
      };

    case POSTS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case POSTS_LIST: {
      return {
        posts: payload,
        loading: false,
      };
    }
    case UPDATE_POST: {
      return {
        ...state,
        posts: {
          ...state.posts,
          items: state.posts.items.map((post) =>
            post.id === payload.id ? payload : post
          ),
        },
        loading: false,
      };
    }
    default:
      return state;
  }
}
