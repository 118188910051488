import {
  CREATE_QUESTION,
  UPDATE_QUESTION,
  DELETE_QUESTION,
  CREATE_QR,
  CONVERSATION_ERROR,
  QUESTIONS_LIST,
  QUESTION_UPLOAD_FILE,
  CLEAR_UPLOAD_FILE
} from "../actions/types";

const initialState = {
  loading: true,
  question: null,
  questions: [],
  files: [],
  quickreply: null,
  quickreplies: [],
  error: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CLEAR_UPLOAD_FILE:
      return {
        ...state,
        files: [],
        loading: false,
      };
    case QUESTION_UPLOAD_FILE:
      return {
        ...state,
        files: [...state.files, payload],
        loading: false,
      };
    case CREATE_QUESTION:
      return {
        ...state,
        questions: [...state.questions, payload],
        question: payload,
        loading: false,
      };

    case UPDATE_QUESTION: {
      return {
        ...state,
        questions: state.questions.map((question) =>
          question.id === payload.id ? payload : question
        ),
        loading: false,
      };
    }
    case DELETE_QUESTION: {
      return {
        ...state,
        questions: state.questions.filter((item) => item.id !== payload),
        loading: false,
      };
    }

    case CREATE_QR:
      return {
        ...state,
        quickreplies: [payload.quickReplies],
        loading: false,
      };

    case CONVERSATION_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    case QUESTIONS_LIST: {
      return {
        ...state,
        questions: payload,
        loading: false,
      };
    }

    default:
      return state;
  }
}
