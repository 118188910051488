import {
  UPLOAD_FILE,
  ADD_STORY,
  STORIES_LIST,
  UPDATE_STORY,
  DELETE_STORY,
  STORY_ERROR,
} from "../actions/types";

const initialState = {
  loading: true,
  file: {},
  stories: [],
  error: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case STORIES_LIST: {
      return {
        stories: payload,
        loading: false,
      };
    }
    case DELETE_STORY: {
      return {
        ...state,
        stories: {
          ...state.stories,
          items: state.stories.items.filter((item) => item.id !== payload),
        },
        loading: false,
      };
    }
    case UPDATE_STORY: {
      return {
        ...state,
        stories: {
          ...state.stories,
          items: state.stories.items.map((story) =>
            story.id === payload.id ? payload : story
          ),
        },
        loading: false,
      };
    }
    case ADD_STORY:
      return {
        ...state,
        stories: {
          ...state.stories,
          items: [payload, ...state.stories.items],
        },
        loading: false,
      };
    case UPLOAD_FILE: {
      return {
        ...state,
        file: payload,
        loading: false,
      };
    }

    case STORY_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    default:
      return state;
  }
}
