import axios from "axios";

import {
  CREATE_ESTABLISHMENT,
  ESTABLISHMENTS_LIST,
  ESTABLISHMENT_ERROR,
  UPDATE_ESTABLISHMENT,
  FULL_ESTABLISHMENTS_LIST,
} from "./types";
import { setAlert } from "./alert";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

// Invite Admin
export const createEstablishment = (formData) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify(formData);

  try {
    const res = await axios.post(`${BASE_URL}/establishments`, body, config);

    dispatch({
      type: CREATE_ESTABLISHMENT,
      payload: res.data,
    });
    dispatch(setAlert("Etablissement crée", "success"));
  } catch (err) {
    dispatch({
      type: ESTABLISHMENT_ERROR,
      payload: err.response.data.message,
    });
    dispatch(
      setAlert(`Echec de l'envoi: ${err.response.data.message}`, "danger")
    );
  }
};

//Get all establishments
export const getAllEstablishmentsList = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(`${BASE_URL}/establishments/all`, config);

    dispatch({
      type: FULL_ESTABLISHMENTS_LIST,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: ESTABLISHMENT_ERROR,
      payload: err.response.data.message,
    });
    dispatch(
      setAlert(`Echec de l'envoi: ${err.response.data.message}`, "danger")
    );
  }
};

//Get establishments List
export const getEstablishmentsList = (page, limit) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(
      `${BASE_URL}/establishments/?page=${page}&limit=${limit}`,
      config
    );

    dispatch({
      type: ESTABLISHMENTS_LIST,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: ESTABLISHMENT_ERROR,
      payload: err.response.data.message,
    });
    dispatch(
      setAlert(`Echec de l'envoi: ${err.response.data.message}`, "danger")
    );
  }
};

//update establishment

export const updateEstablishment = (formData) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify(formData);

  try {
    const res = await axios.patch(
      `${BASE_URL}/establishments/${formData.id}`,
      body,
      config
    );
    dispatch({
      type: UPDATE_ESTABLISHMENT,
      payload: res.data,
    });
    dispatch(setAlert("l'établissment à été mis à jour", "success"));
  } catch (err) {
    dispatch({
      type: ESTABLISHMENT_ERROR,
      payload: err.response.data.message,
    });
    dispatch(
      setAlert(`Echec de l'envoi: ${err.response.data.message}`, "danger")
    );
  }
};
