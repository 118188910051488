import React, { useState, useEffect } from "react";
import "./academicYearSections.scss";
import AcademicListItem from "./AcademicYearListItem";
import AcademicYearForm from "./AcademicYearForm";
import { getAcademicYearList } from "../../actions/academic";
import Pagination from "../layout/Pagination";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const AcademicYearsSection = ({ getAcademicYearList, academicYears }) => {
  const LIMIT_SIZE = 5;

  const [academicYearData, changeAcademicYearData] = useState();
  const [selectedElement, changeSelectedElement] = useState();
  const [pageNumber, changePageNumber] = useState(1);

  useEffect(() => {
    getAcademicYearList(pageNumber, LIMIT_SIZE);
  }, [getAcademicYearList, pageNumber]);

  const openAcademicYearCreationForm = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeAcademicYearData(null);
    changeSelectedElement(null)
  };
  const displayAcademicYearData = (data) => {
    changeSelectedElement(data.id);
    changeAcademicYearData({
      ...academicYearData,
      isCurrent:data.isCurrent,
      name: data.name,
      id: data.id,
    });
  };
  const onChangePageNumber = (number) => {
    changePageNumber(number);
  };
  return (
    <div className="adminsSectiondMainContainer">
      <div className="adminsListContainer">
        {academicYears &&
          academicYears.items &&
          academicYears.items.map((academicYear) => (
            <AcademicListItem
              key={academicYear.id}
              displayAcademicYearData={displayAcademicYearData}
              academicYearData={academicYear}
              isSelected={selectedElement === academicYear.id}
            />
          ))}
        <Pagination
          totalPages={academicYears && academicYears.meta ? academicYears.meta.totalPages : 1}
          page_number={pageNumber}
          onChangePageNumber={onChangePageNumber}
        />
        <div className="adminsSectionBtnContainer">
          <button onClick={openAcademicYearCreationForm} className="Btn">
            +
          </button>
        </div>
      </div>
      <div className="adminsFormContainer">
        <AcademicYearForm academicYearData={academicYearData} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
    academicYears: state.academicYears.academicYears,
});

AcademicYearsSection.propTypes = {
    getAcademicYearList: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { getAcademicYearList })(AcademicYearsSection);

