import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import StoriesListItem from "./StoriesListItem";
import StoryForm from "./StoryForm";
import { getStoriesList } from "../../actions/story";
import Pagination from "../layout/Pagination";

import "./storiesSection.scss";
const StoriesSection = ({ getStoriesList, stories, loading }) => {
  const LIMIT_SIZE = 5;
  const [storyData, changeStoryData] = useState();
  const [selectedElement, changeSelectedElement] = useState();
  const [pageNumber, changePageNumber] = useState(1);

  useEffect(() => {
    getStoriesList(pageNumber, LIMIT_SIZE);
  }, [getStoriesList, pageNumber]);

  const openStoryCreationForm = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeStoryData(null);
    changeSelectedElement(null);
  };

  const displayStoryData = useCallback((data) => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeSelectedElement(data.id);
    changeStoryData({
      createdAt: data.createdAt,
      updatedAt: data.updatedAt,
      id: data.id,
      postType: data.postType,
      vuesCounter: data.vuesCounter,
      type: data.type,
      attachedUrl: data.attachedUrl,
      mediaName: data.mediaName,
    });
  }, []);

  const onChangePageNumber = (number) => {
    changePageNumber(number);
  };

  useEffect(() => {
    if (!loading && stories && stories.items && stories.items.length > 0) {
      displayStoryData(stories.items[0]);
    }
  }, [loading, displayStoryData, stories]);
  
  return (
    <div className="adminsSectiondMainContainer">
      <div className="storiesListContainer">
        {stories &&
          stories.items &&
          stories.items.map((story) => (
            <StoriesListItem
              key={story.id}
              displayStoryData={displayStoryData}
              storyData={story}
              isSelected={selectedElement === story.id}
            />
          ))}
        <Pagination
          totalPages={stories && stories.meta ? stories.meta.totalPages : 1}
          page_number={pageNumber}
          onChangePageNumber={onChangePageNumber}
        />
        <div className="adminsSectionBtnContainer">
          <button onClick={openStoryCreationForm} className="Btn">
            +
          </button>
        </div>
      </div>
      <div className="adminsFormContainer">
        <StoryForm storyData={storyData} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  stories: state.story.stories,
  loading: state.story.loading,
});

StoriesSection.propTypes = {
  getStoriesList: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { getStoriesList })(StoriesSection);