import React from "react";
import PropTypes from "prop-types";

const Pagination = ({ onChangePageNumber, totalPages, page_number }) => {
  const pageChange = (i) => {
    onChangePageNumber(i);
  };

  const createTabulation = () => {
    let table = [];
    for (let i = 1; i <= totalPages; i++) {
      table.push(
        <li key={i} className={"page-item  " + (page_number === i ? "active " : "")}>
          <button className="page-link" onClick={() => pageChange(i)}>
            {i}
          </button>
        </li>
      );
    }
    return table;
  };

  return (
    <nav>
      <ul className="pagination justify-content-end">
        {totalPages > 1 && (
          <li className={"page-item " + (1 === page_number ? "disabled" : "")}>
            <button
              className="page-link"
              onClick={
                1 < page_number ? () => pageChange(page_number - 1) : null
              }
            >
              <span className="sr-only">Précedent</span>
            </button>
          </li>
        )}
        {createTabulation()}
        {totalPages > 1 && (
          <li
            className={
              "page-item " + (totalPages === page_number ? "disabled" : "")
            }
          >
            <button
              className="page-link"
              onClick={
                page_number < totalPages
                  ? () => pageChange(page_number + 1)
                  : null
              }
            >
              <span className="sr-only">Suivant</span>
            </button>
          </li>
        )}
      </ul>
    </nav>
  );
};

Pagination.propTypes = {
  onChangePageNumber: PropTypes.func,
  page_number: PropTypes.any,
  totalPages: PropTypes.any,
};

export default Pagination;
