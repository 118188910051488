import React from "react";
import "./Dashboard.scss";
const DashboardItem = ({ mainText, secondryText, btnText , link ,history}) => {
  return (
    <div onClick={()=>history.push(link)} className="dashboardItemContainer">
      <div className="dashboardItemTextContainer">
        <p className="dashboardItemMainText">{mainText}</p>
        <p className="dashboardItemSecondaryText">{secondryText}</p>
      </div>
      <button className="dashBoardItemBtn">{btnText}</button>
    </div>
  );
};

export default DashboardItem;
