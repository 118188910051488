import React, { useEffect, useState } from "react";
import moment from "moment";
import { updateQuestionsBox } from "../../actions/questionBox";
import PropTypes from "prop-types";
import { connect } from "react-redux";
const QuestionForm = ({ questionData, updateQuestionsBox }) => {
  const [formData, setFormData] = useState({
    askedBy: {},
    isSeen: false,
    question: "",
    id: null,
  });
  useEffect(() => {
    setFormData({
      askedBy: questionData ? questionData.askedBy : {},
      isSeen: questionData ? questionData.isSeen : false,
      question: questionData ? questionData.question : "",
      id: questionData ? questionData.id : null,
    });
  }, [questionData]);

  const { question, askedBy, isSeen, id } = formData;

  const onChange = () => {
    updateQuestionsBox(id, !isSeen);
    setFormData({ ...formData, isSeen: !isSeen });
  };

  return question.length > 0 ? (
    <>
      <p className="questionFormTitle">
        Question de la part de {askedBy.firstName}{" "}
        {moment().diff(askedBy.dateOfBirth, "years")} ans :{" "}
      </p>
      <div className="questionTextContainer">
        <p className="questionText">{question}</p>
        <input
          style={{
            position: "absolute",
            alignSelf: "center",
            width: "2rem",
            right: "2rem",
            cursor: "pointer",
          }}
          name="isSeen"
          type="checkbox"
          checked={isSeen}
          onChange={()=>onChange()}
        />
      </div>
    </>
  ) : (
    <p className="questionFormTitle">Sélectionner une question</p>
  );
};

QuestionForm.propTypes = {
  updateQuestionsBox: PropTypes.func.isRequired,
};

export default connect(null, {
  updateQuestionsBox,
})(QuestionForm);
