import React, { useEffect } from "react";
import { connect } from "react-redux";

import { deleteQuestion } from "../../actions/conversation";
const ConversationBlocs = ({
  createBloc,
  deleteQuestion,
  conversationsList,
  displayConversationData,
  isSelected,
  disableForm,
}) => {
  const sortByDate = (arr) => {
    arr.sort(function (a, b) {
      return Number(new Date(a.createdAt)) - Number(new Date(b.createdAt));
    });
    return arr;
  };
  const SortedArray = sortByDate(conversationsList);
  useEffect(() => displayConversationData(null), []);
  return (
    <div className="discussionListitemMainContainer">
      {SortedArray.map((bloc, index) => (
        <div key={index} className="blocContainer">
          {!bloc.isRoot && !disableForm && (
            <div
              onClick={() => {
                const response = window.confirm(`
              êtes-vous sûr de vouloir supprimer le bloc ?`);
                if (response) {
                  createBloc();
                  deleteQuestion(bloc.id);
                }
              }}
              className="close-container"
            >
              <div className="leftright"></div>
              <div className="rightleft"></div>
            </div>
          )}

          <div
            onClick={() => displayConversationData(bloc)}
            style={{ background: bloc.isRoot ? "#e89a583d" : "#fff" }}
            className={
              isSelected === bloc.id
                ? "discussionListitem-active"
                : "discussionListitem"
            }
          >
            <div>
              <p>{bloc.name}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default connect(null, { deleteQuestion })(ConversationBlocs);
