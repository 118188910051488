import React from "react";
import { withRouter } from "react-router-dom";

const AcademicListItem = ({
  displayAcademicYearData,
  academicYearData,
  isSelected,
}) => {
  return (
    <div
      onClick={() => displayAcademicYearData(academicYearData)}
      className={
        isSelected
          ? "academicListitemMainContainer-active"
          : "academicListitemMainContainer"
      }
    >
      <div className="academicListitemTextContainer">
        <p className="adminListitemName">{academicYearData.name}</p>
      </div>
      {academicYearData.isCurrent && (
        <p className="academicState">Année courante</p>
      )}
    </div>
  );
};

export default withRouter(AcademicListItem);
