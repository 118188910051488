import React, { useState, useEffect, useCallback } from "react";
import "./adminsSections.scss";
import AdminsListItem from "./AdminsListItem";
import AdminForm from "./AdminForm";
import { getAdminsList } from "../../actions/admins";
import Pagination from "../layout/Pagination";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const AdminsSection = ({ getAdminsList, admins, loading }) => {
  const LIMIT_SIZE = 5;

  const [adminData, changeAdminData] = useState();
  const [selectedElement, changeSelectedElement] = useState();
  const [pageNumber, changePageNumber] = useState(1);

  useEffect(() => {
    getAdminsList(pageNumber, LIMIT_SIZE);
  }, [getAdminsList, pageNumber]);

  const openAdminCreationForm = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeAdminData(null);
    changeSelectedElement(null);
  };
  const displayAdminData = useCallback((data) => {
    changeSelectedElement(data.id);
    changeAdminData({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      id: data.id,
    });
  }, []);

  const onChangePageNumber = (number) => {
    changePageNumber(number);
  };

  useEffect(() => {
    if (!loading && admins.items.length>0) {
      displayAdminData(admins.items[0]);
    }
  }, [loading, displayAdminData,admins.items]);

  return (
    <div className="adminsSectiondMainContainer">
      <div className="adminsListContainer">
        {admins &&
          admins.items &&
          admins.items.map((admin) => (
            <AdminsListItem
              key={admin.id}
              displayAdminData={displayAdminData}
              adminData={admin}
              isSelected={selectedElement === admin.id}
            />
          ))}
        <Pagination
          totalPages={admins && admins.meta ? admins.meta.totalPages : 1}
          page_number={pageNumber}
          onChangePageNumber={onChangePageNumber}
        />
        <div className="adminsSectionBtnContainer">
          <button onClick={openAdminCreationForm} className="Btn">
            +
          </button>
        </div>
      </div>
      <div className="adminsFormContainer">
        <AdminForm adminData={adminData} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  admins: state.admins.admins,
  loading: state.admins.loading,
});

AdminsSection.propTypes = {
  getAdminsList: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { getAdminsList })(AdminsSection);
