import * as moment from 'moment-timezone';

moment.locale('fr');

export const getCurrentMonth = () => moment().format('MM');

export const getCurrentYear = () => moment().format('YYYY');

export const getNow = () =>
  new Date(moment.tz('Europe/Paris').format("DYYYY-MM-DD HH:mm:ss"));

export const getCurrentAcademicYear = () => {
  const currentYear = new Date().getFullYear();
  const currentMonth = getCurrentMonth();
  return ['09', '10', '11', '12'].includes(currentMonth)
    ? `${currentYear}-${currentYear + 1}`
    : `${currentYear - 1}-${currentYear}`;
};