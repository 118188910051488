import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import BiologicIcon from "../../assets/images/bio-sujet@2x.png";
import SocialIcon from "../../assets/images/social-sujet@2x.png";
import PsychoIcon from "../../assets/images/psycho-sujet@2x.png";
import IntroIcon from "../../assets/images/avatarSpeach.svg";
import SpeachLogo from "../../assets/images/avatarSpeach.svg";
import Stories from "react-insta-stories";

const StoriesListItem = ({ storyData, isSelected, displayStoryData }) => {
  const [storyDisplay, setStoryDisplay] = useState(false);

  const myRef = React.createRef();

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return function cleanup() {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const handleClickOutside = (e) => {
    if (!myRef.current.contains(e.target)) {
      setStoryDisplay(false);
    } else {
      setStoryDisplay(true);
    }
  };

  return (
    <div
      onClick={() => displayStoryData(storyData)}
      className={
        isSelected
          ? "storiesListitemMainContainer-active"
          : "storiesListitemMainContainer"
      }
    >
      <div className="storiesListitemHeader">
        <div className="storiesListitemHeaderLeftSection">
          <img
            className="storiesListitemHeaderImg"
            src={
              storyData.type === "permanent"
                ? storyData.postType === "bio"
                  ? BiologicIcon
                  : storyData.postType === "social"
                  ? SocialIcon
                  : storyData.postType === "psycho"
                  ? PsychoIcon
                  : IntroIcon
                : SpeachLogo
            }
            alt="BiologicIcon"
          />
          <div className="storiesListitemHeaderText">
            <p className="storiesListitemHeaderMainText">
              Story{" "}
              {storyData.type === "permanent" ? "permanente" : "temporaire"}{" "}
              {storyData.type === "permanent"
                ? storyData.postType === "bio"
                  ? "Biologique"
                  : storyData.postType === "social"
                  ? "Social"
                  : storyData.postType === "psycho"
                  ? "Psycho-affectif"
                  : "Présentation"
                : ""}
            </p>
            <p className="storiesListitemHeaderSecondaryText">
              Publiée le{" "}
              {moment(storyData.createdAt).utc().format("DD-MM-YYYY HH:mm")}
            </p>
          </div>
        </div>
        <p className="storiesListitemHeaderCounter">
          Vue {storyData.vuesCounter} fois
        </p>
      </div>
      <img
        className="storiesListitemBodyImg"
        src={storyData.mediaName}
        alt="*"
      />
      <div ref={myRef} className="storiesBtnsContainers">
        <button className="Btn">Visualiser</button>
      </div>
      {storyDisplay && (
        <div className="storyPreviewContainer">
          <div className="storyPreview">
            <Stories
              storyStyles={{
                width: "100%",
                height: "100%",
              }}
              stories={[storyData.mediaName]}
              defaultInterval={1500}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default withRouter(StoriesListItem);
