import React, { useState, useEffect, useCallback } from "react";
import "./studentsSections.scss";
import StudentsListItem from "./StudentsListItem";
import StudentForm from "./StudentForm";
import { getStudentsList } from "../../actions/student";
import { getAllEstablishmentsList } from "../../actions/establishment";
import Pagination from "../layout/Pagination";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const StudentsSection = ({
  getAllEstablishmentsList,
  getStudentsList,
  students,
  establishments,
  loading,
}) => {
  const LIMIT_SIZE = 5;

  const [studentData, changeStudentData] = useState();
  const [selectedElement, changeSelectedElement] = useState();
  const [pageNumber, changePageNumber] = useState(1);
  const [establishmentSelected, changeEstablishmentSelected] = useState(1);

  useEffect(() => {
    getStudentsList(pageNumber, LIMIT_SIZE,establishmentSelected);
  }, [getStudentsList, pageNumber,establishmentSelected]);

  useEffect(() => {
    getAllEstablishmentsList();
  }, [getAllEstablishmentsList]);

  const displayStudentData = useCallback((data) => {
    changeSelectedElement(data.id);
    changeStudentData({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      dateOfBirth: data.dateOfBirth,
      establishment: data.establishment.name,
      isActive: data.isActive,
      id: data.id,
    });
  }, []);

  const onChangePageNumber = (number) => {
    changePageNumber(number);
  };
  const onChange = (e) => {
    changeEstablishmentSelected(e.target.value);
  };
  useEffect(() => {
    if (!loading && students.items.length > 0) {
      displayStudentData(students.items[0]);
    }
  }, [
    loading,
    changeEstablishmentSelected,
    displayStudentData,
    establishments,
    students.items,
  ]);

  return (
    <div className="adminsSectiondMainContainer">
      <div className="adminsListContainer">
        {!loading && (
          <select
            onChange={(e) => onChange(e)}
            value={establishmentSelected}
            name="next"
          >
            {establishments.map((establishment) => (
              <option key={establishment.name} value={establishment.id}>
                {establishment.name}
              </option>
            ))}
          </select>
        )}
        {students &&
          students.items &&
          students.items.map((student) => (
            <StudentsListItem
              key={student.id}
              displayStudentData={displayStudentData}
              studentData={student}
              isSelected={selectedElement === student.id}
            />
          ))}
        <Pagination
          totalPages={students && students.meta ? students.meta.totalPages : 1}
          page_number={pageNumber}
          onChangePageNumber={onChangePageNumber}
        />
      </div>
      <div className="adminsFormContainer">
        <StudentForm studentData={studentData} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  students: state.students.students,
  establishments: state.establishments.establishments_all,
  loading: state.students.loading,
});

StudentsSection.propTypes = {
  getStudentsList: PropTypes.func.isRequired,
  getAllEstablishmentsList: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  getAllEstablishmentsList,
  getStudentsList,
})(StudentsSection);
