import axios from "axios";

import { STATS_LIST, STATS_ERROR } from "./types";
import { setAlert } from "./alert";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

//Get Statistics
export const getStatistics =
  (establishmentId, year, month) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const monthFormated = month < 9 ? "0" + (month + 1) : month + 1;

    try {
      const res = await axios.get(
        `${BASE_URL}/kpis/establishments/${establishmentId}/years/${year}/months/${monthFormated}`,
        config
      );
      dispatch({
        type: STATS_LIST,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: STATS_ERROR,
        payload: err.response.data.message,
      });
      dispatch(
        setAlert(`Echec de l'envoi: ${err.response.data.message}`, "danger")
      );
    }
  };
