import {
  STUDENTS_ERROR,
  STUDENTS_LIST,
  UPDATE_STUDENT,
  ARCHIVE_STUDENT
} from "../actions/types";

const initialState = {
  loading: true,
  student: null,
  students: [],
  error: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case STUDENTS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case STUDENTS_LIST: {
      return {
        students: payload,
        loading: false,
      };
    }
    case UPDATE_STUDENT: {
      return {
        ...state,
        students: {
          ...state.students,
          items: state.students.items.map((student) =>
            student.id === payload.id ? payload : student
          ),
        },
        loading: false,
      };
    }
    case ARCHIVE_STUDENT: {
      return {
        ...state,
        students: {
          ...state.students,
          items: state.students.items.filter((item) => item.id !== payload),
        },
        loading: false,
      };
    }
    default:
      return state;
  }
}
