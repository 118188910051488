import {
  ACADEMIC_ERROR,
  ACADEMIC_LIST,
  ADD_ACADEMIC_YEAR,
  UPDATE_ACADEMIC_YEAR
} from "../actions/types";

const initialState = {
  loading: true,
  academicYear: null,
  academicYears: [],
  error: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ACADEMIC_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case ADD_ACADEMIC_YEAR: {
     const items= state.academicYears.items.map((academicYear) =>(
      academicYear={...academicYear,isCurrent:false}
     )
      )
      return {
        academicYears: {
          ...state.academicYears,
          items: [...items, payload],
        },
        loading: false,
      };
    }

    case UPDATE_ACADEMIC_YEAR: {
      return {
        ...state,
        academicYears: {
          ...state.academicYears,
          items: state.academicYears.items.map((academicYear) =>
          academicYear.id === payload.id ? payload : academicYear={...academicYear,isCurrent:false}
          ),
        },
        loading: false,
      };
    }
    case ACADEMIC_LIST: {
      return {
        academicYears: payload,
        loading: false,
      };
    }
    default:
      return state;
  }
}
