import React, { useEffect, useState } from "react";
import SpeachBubble from "./SpeachBubble";

const ConversationSimulator = ({ questionsList, changeSimulatorState }) => {
  const [questions, setQuestions] = useState([]);
  const [reload, provokeReload] = useState(false);

  useEffect(() => {
    const initialQuestion = questionsList.filter((question) => {
      return question.isRoot;
    });
    setQuestions(initialQuestion);
  }, [questionsList, reload]);

  const reloadConversation = () => {
    provokeReload(!reload);
  };
  const questionsHandler = (data) => {
    const findelement = questionsList.findIndex((item) => item.id === data);
    setQuestions((prevState) => [...prevState, questionsList[findelement]]);
  };

  return (
    <div className="discutionSimulatorContainer">
      <div className="discutionSimulatorHeader">
        <p onClick={() => reloadConversation()} className="simulatorReload">
          &#8635;
        </p>
        <p onClick={() => changeSimulatorState()} className="simulatorClose">
          &#215;
        </p>
      </div>
      <div className="discutionSimulatorBody">
        {questions.map((question, index) => (
          <SpeachBubble
            reloadState={reload}
            questionsHandler={questionsHandler}
            key={index}
            questionData={question}
          />
        ))}
      </div>
    </div>
  );
};
export default ConversationSimulator;
