import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  createQuestion,
  getConversationList,
  questionUploadFiles,
} from "../../actions/conversation";
import ConversationBuble from "./ConversationBuble";
import SpeachLogo from "../../assets/images/avatarSpeach.svg";
import ConversationSimulator from "./simulator/ConversationSimulator";

const AddDiscution = ({
  questionsList,
  createQuestion,
  questionUploadFiles,
  getConversationList,
  createBloc,
  postData,
  conversationData,
  conversationsList,
  disableForm,
  filesList,
  loading,
}) => {
  const BASE_URL = process.env.REACT_APP_SERVER_URL;
  const SEPARATOR = "$$@SPEACH@$$";
  const [simulatorState, SetsimulatorState] = useState(false);

  const [questionList, setQuestionList] = useState([""]);
  const [imagesList, setImagesList] = useState([]);
  useEffect(() => {
    if (
      !loading &&
      filesList.length > 0 &&
      imagesList.length === filesList.length
    ) {
      const listedQuickReplies = quickReplies.map((qr) => {
        if (qr.nextQuestion) {
          qr.next = qr.nextQuestion.id;
        }
        return qr;
      });
      const splitedContent = questions.content.split(SEPARATOR);
      const indexesArray = imagesList.map((content) => content.index);
      indexesArray.map(
        (indexNumber, index) =>
          (splitedContent[
            indexNumber
          ] = `${BASE_URL}/download/assets${filesList[index].filename}`)
      );

      const questionsJoined = splitedContent.join(SEPARATOR);

      questions.name = title;
      questions.content = questionsJoined;
      createQuestion(questions, postData.id, listedQuickReplies);
      setImagesList([]);
    }
  }, [filesList, loading]);

  useEffect(() => {
    getConversationList(postData.id);
  }, [getConversationList, postData]);

  const [questions, setQuestions] = useState({
    content: "",
    id: "",
    level: 0,
  });
  const [title, setTitle] = useState("");
  const [quickReplies, setQuickReplies] = useState([]);
  useEffect(() => {
    setQuestions({
      content: conversationData ? conversationData.content : "",
      id: conversationData ? conversationData.id : null,
      level: conversationData ? conversationData.level : 0,
      name: conversationData ? conversationData.name : "",
      isRoot: conversationData ? conversationData.isRoot : false,
    });
    const questionContent = conversationData
      ? conversationData.content.split(SEPARATOR)
      : [];
    setQuestionList(questionContent);
    setQuickReplies(conversationData ? conversationData.quickReplies : []);
  }, [conversationData]);

  useEffect(() => {
    setTitle(conversationData ? conversationData.name : "");
  }, [conversationData]);

  const quickReplyHandler = (data) => {
    setQuickReplies((prevState) => {
      let checkExistence = -1;
      if (data.name != undefined) {
        checkExistence = prevState.findIndex((item) => item.name === data.name);
      }
      let checkIDExistence = -1;
      if (data.id == null) {
        checkExistence = prevState.findIndex((item) => item.name === data.name);
      } else if (data.id != null) {
        checkIDExistence = prevState.findIndex((item) => item.id === data.id);
      }
      const updatedArray = [...prevState];

      if (data.value === "deleted") {
        updatedArray.splice(
          checkExistence > -1 ? checkExistence : checkIDExistence,
          1
        );
        return updatedArray;
      } else {
        if (checkExistence > -1) {
          updatedArray[checkExistence] = data;
          return updatedArray;
        }
        if (checkIDExistence > -1) {
          updatedArray[checkIDExistence] = data;
          return updatedArray;
        } else {
          return [...prevState, data];
        }
      }
    });
  };

  const changeSimulatorState = () => SetsimulatorState(!simulatorState);

  const onSubmit = async (e) => {
    e.preventDefault();
    const listedQuickReplies = quickReplies.map((qr) => {
      if (qr.nextQuestion) {
        qr.next = qr.nextQuestion.id;
      }
      return qr;
    });
    questions.name = title;
    if (imagesList.length > 0) {
      imagesList.map((image) => {
        questionUploadFiles(image.file);
      });
      return;
    } else {
      createQuestion(questions, postData.id, listedQuickReplies);
      createBloc();
    }
  };

  const changeTitle = (e) => {
    setTitle(e.target.value);
  };

  const handleQuestionsBubbles = useCallback((index) => {
    let checkIDExistence = -1;
    setImagesList((prevState) => {
      checkIDExistence = prevState.findIndex((item) => item.index === index);
      let updatedArray = [...prevState];
      if (index > -1 && checkIDExistence > -1) {
        updatedArray.splice(checkIDExistence, 1);
        return updatedArray;
      }
      return [...prevState];
    });
    let questionsJoined = "";

    setQuestionList((prevState) => {
      let updatedArray = [...prevState];
      if (index > -1) {
        updatedArray.splice(index, 1);
        questionsJoined = updatedArray.join(SEPARATOR);
        return updatedArray;
      } else {
        return [...prevState, ""];
      }
    });

    setQuestions((prevState) => ({
      ...prevState,
      content: questionsJoined,
    }));
  }, []);

  const questionsHandler = (data, index, file = false) => {
    setQuestionList((prevState) => {
      const updatedArray = prevState;
      updatedArray[index] = data.content;
      return updatedArray;
    });

    const questionsJoined = questionList.join(SEPARATOR);
    if (file) {
      setImagesList((prevState) => {
        const updatedArray = prevState;
        updatedArray.push(file);
        return updatedArray;
      });
    }

    setQuestions((prevState) => ({
      ...prevState,
      name: title,
      content: questionsJoined,
    }));
  };
  return (
    <div className="discutionContainer">
      {!disableForm && (
        <>
          <label className="academicCheckboxLabel">
            Nom du bloc {questions.isRoot ? "(initial)" : ""}
          </label>
          <input
            style={{
              width: "30%",
              padding: "1rem",
            }}
            type="text"
            name="title"
            value={title}
            onChange={(e) => changeTitle(e)}
          />
          {!disableForm && (
            <div
              onClick={() => handleQuestionsBubbles(-1)}
              className="addBubleBtn"
            >
              + Nouvelle bulle
            </div>
          )}
          <ConversationBuble
            disableForm={disableForm}
            conversationsList={conversationsList}
            handleQuestionsBubbles={handleQuestionsBubbles}
            questionsHandler={questionsHandler}
            quickReplyHandler={quickReplyHandler}
            setQuickReplies={setQuickReplies}
            name={title}
            data={questions}
            quickReplies={quickReplies}
            questionList={questionList}
          />
          {title.length > 0 && (
            <div className="adminsFormBtnContainer">
              <button onClick={() => createBloc()} className="Btn returnBtn">
                Fermer
              </button>
              {!disableForm && (
                <button onClick={onSubmit} className="Btn">
                  Enregistrer
                </button>
              )}
            </div>
          )}
        </>
      )}

      {simulatorState && (
        <ConversationSimulator
          changeSimulatorState={changeSimulatorState}
          questionsList={questionsList}
        />
      )}
      <div onClick={changeSimulatorState} className="discutionSimulatorBtn">
        <img className="simulatorListitemImage" src={SpeachLogo} alt="admin" />
      </div>
    </div>
  );
};

AddDiscution.propTypes = {
  createQuestion: PropTypes.func.isRequired,
  getConversationList: PropTypes.func.isRequired,
  questionUploadFiles: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  questionsList: state.conversations.questions,
  filesList: state.conversations.files,
  actualQuestion: state.conversations.question,
  quickRepliesList: state.conversations.quickreplies,
  loading: state.conversations.loading,
});

export default connect(mapStateToProps, {
  createQuestion,
  getConversationList,
  questionUploadFiles,
})(AddDiscution);
