import {
  INVITE_ADMIN,
  ADMIN_ERROR,
  ADMINS_LIST,
  UPDATE_ADMIN,
  ARCHIVE_ADMIN,
} from "../actions/types";

const initialState = {
  loading: true,
  admin: null,
  admins: [],
  error: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case INVITE_ADMIN:
      return {
        ...state,
        admins: {
          ...state.admins,
          items: [payload, ...state.admins.items],
        },
        loading: false,
      };
    case ADMIN_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case ADMINS_LIST: {
      return {
        admins: payload,
        loading: false,
      };
    }
    case UPDATE_ADMIN: {
      return {
        ...state,
        admins: {
          ...state.admins,
          items: state.admins.items.map((admin) =>
            admin.id === payload.id ? payload : admin
          ),
        },
        loading: false,
      };
    }
    case ARCHIVE_ADMIN: {
      return {
        ...state,
        admins: {
          ...state.admins,
          items: state.admins.items.filter((item) => item.id !== payload),
        },
        loading: false,
      };
    }
    default:
      return state;
  }
}
