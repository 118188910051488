import React from "react";
import { withRouter } from "react-router-dom";
import EstablishmentIcon from "../../assets/images/establishment-icon.png";
const StatsEstablishmentsListItem = ({
  displayEstablishmentData,
  establishmentData,
  isSelected,
}) => {
  return (
    <div
      onClick={() => displayEstablishmentData(establishmentData)}
      className={
        isSelected
          ? "adminListitemMainContainer-active"
          : "adminListitemMainContainer"
      }
    >
      <img
        className="adminListitemImage"
        src={EstablishmentIcon}
        alt="admin"
      />
      <div className="adminListitemTextContainer">
        <p className="adminListitemName">{establishmentData.name}</p>
        <p className="adminListitemRole">
           code : {establishmentData.code}
        </p>
      </div>
    </div>
  );
};

export default withRouter(StatsEstablishmentsListItem);
