import axios from "axios";

import {
  ACADEMIC_LIST,
  ADD_ACADEMIC_YEAR,
  UPDATE_ACADEMIC_YEAR,
  ACADEMIC_ERROR,
} from "./types";
import { setAlert } from "./alert";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

//Get academic year List
export const getAcademicYearList = (page, limit) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(
      `${BASE_URL}/academic-years/?isHistory=true&page=${page}&limit=${limit}`,
      config
    );

    dispatch({
      type: ACADEMIC_LIST,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: ACADEMIC_ERROR,
      payload: err.response.data.message,
    });
    dispatch(
      setAlert(`Echec de l'envoi: ${err.response.data.message}`, "danger")
    );
  }
};

// Add scholar year
export const addAcademicYear = (formData) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify(formData);

  try {
    const res = await axios.post(`${BASE_URL}/academic-years`, body, config);

    dispatch({
      type: ADD_ACADEMIC_YEAR,
      payload: res.data,
    });
    dispatch(setAlert("Année scolaire ajoutée", "success"));
  } catch (err) {
    dispatch({
      type: ACADEMIC_ERROR,
      payload: err.response.data.message,
    });
    dispatch(
      setAlert(`Echec de l'envoi: ${err.response.data.message}`, "danger")
    );
  }
};

//update scholar year

export const updateAcademicYear = (formData) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify(formData);

  try {
    const res = await axios.patch(
      `${BASE_URL}/academic-years/${formData.id}`,
      body,
      config
    );

    dispatch({
      type: UPDATE_ACADEMIC_YEAR,
      payload: res.data,
    });

    dispatch(setAlert("l'année scolaire à été mis à jour", "success"));
  } catch (err) {
    dispatch({
      type: ACADEMIC_ERROR,
      payload: err.response.data.message,
    });
    dispatch(
      setAlert(`Echec de l'envoi: ${err.response.data.message}`, "danger")
    );
  }
};
