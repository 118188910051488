import React, { useState } from "react";
import { Redirect } from "react-router-dom";

import "./auth.scss";
import SpeachLogo from "../../assets/images/avatarSpeach.svg";
import PropTypes from "prop-types";
import { setPassword } from "../../actions/auth";
import { connect } from "react-redux";
import getUrlParam from "../../utils/getUrlParam";
import checkStrengthPassword from "../../utils/checkPasswordStrength";
import showPassword from "../../assets/images/ic-visibility.png";
import hidePassword from "../../assets/images/ic-unvisibility.png";
const SetPassword = ({ history, setPassword, isAuthenticated }) => {
  const [formData, setFormData] = useState({
    confirmPassword: "",
    password: "",
    invitationToken: getUrlParam("token"),
  });
  const [passwordState, SetPasswordState] = useState(true);
  const changePasswordState = () => SetPasswordState(!passwordState);

  const [error, setError] = useState(null);

  const {confirmPassword, password, invitationToken } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setError(null);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const checkPassword = checkStrengthPassword(password);

    if (confirmPassword !== password) {
      setError(
        "le mot de passe saisi et la confirmation ne sont pas identiques"
      );
    } else {
      if (!checkPassword) {
        setError("mot de passe invalide");
      } else {
        setPassword(password, invitationToken, history);
      }
    }
  };

  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }
  return (
    <div className="forgottenPasswordContainer">
      <img src={SpeachLogo} alt="SpeachLogo" />
      <p className="mainTitle">Définissez votre mot de passe.</p>
      <p className="dashboardItemMainText">
        Choisissez un mot de passe unique pour protéger votre compte Il doit
        contenir au moins 8 caractères avec 1 Majuscule, 1 minuscule et 1
        caractère spécial minimum.
      </p>
      <div className="InputPasswordContainer">
        <input
          type={passwordState ? "password" : "text"}
          className="form-control"
          name="password"
          placeholder="Tapez le mot de passe"
          value={password}
          onChange={(e) => onChange(e)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              onSubmit(e)
            }
          }}
        />
        <img
          onClick={changePasswordState}
          className="passwordImg"
          src={passwordState ? showPassword : hidePassword}
          alt="showPassword"
        />
      </div>
      <div className="InputPasswordContainer">
        <input
          type={passwordState ? "password" : "text"}
          className="form-control"
          name="confirmPassword"
          placeholder="Confirmez le mot de passe"
          value={confirmPassword}
          onChange={(e) => onChange(e)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              onSubmit(e)
            }
          }}
        />
        <img
          onClick={changePasswordState}
          className="passwordImg"
          src={passwordState ? showPassword : hidePassword}
          alt="showPassword"
        />
      </div>

      <p className="error-msg">{error}</p>
      <div className="btnsContainers">
        <button onClick={onSubmit} className="Btn">
          Confirmer
        </button>
      </div>
    </div>
  );
};

SetPassword.propTypes = {
  setPassword: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
  history: PropTypes.any,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { setPassword })(SetPassword);
