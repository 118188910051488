import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { resetPasswordEmail } from "../../actions/auth";

import "./auth.scss";
import SpeachLogo from "../../assets/images/avatarSpeach.svg";
import { Link } from "react-router-dom";

const ForgottenPassword = ({ resetPasswordEmail, isAuthenticated }) => {
  const [email, SetEmail] = useState();

  const onChange = (e) => {
    SetEmail(e.target.value);
  };

  if (isAuthenticated) {
    return <Redirect to="/dashboard" />;
  }

  const onSubmit = (e) => {
    e.preventDefault();
    resetPasswordEmail(email);
  };

  return (
    <div className="forgottenPasswordContainer">
      <img src={SpeachLogo} alt="SpeachLogo" />
      <p className="mainTitle">Veuillez saisir votre adresse e-mail</p>
      <input
        type="email"
        className="form-control"
        name="email"
        placeholder="Email"
        value={email}
        onChange={(e) => onChange(e)}
      />
      <div className="btnsContainers">
        <Link to="login" className="Btn returnBtn">
          Retour
        </Link>
        <button onClick={onSubmit} className="Btn">
          Envoyer
        </button>
      </div>
    </div>
  );
};

ForgottenPassword.propTypes = {
  resetPasswordEmail: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { resetPasswordEmail })(
  ForgottenPassword
);
