import React, { useEffect, useState } from "react";
import { uploadFiles, addPost, updatePost } from "../../actions/posts";
import moment from "moment";

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getCurrentAcademicYear } from "../../utils/moment";

const PostForm = ({
  postData,
  academicYears,
  uploadFiles,
  updatePost,
  openConversationSection,
  disableForm
}) => {

  const BASE_URL = process.env.REACT_APP_SERVER_URL;
  const [formData, setFormData] = useState({
    content: "",
    dateOfPublish: "",
    type: "social",
    academicYear: "",
    id: null,
    mediaName: "",
    externalLink: ""
  });
  const [error, setError] = useState(null);

  useEffect(() => {
    setError(null);
    setFormData({
      content: postData ? postData.content : "",
      dateOfPublish: postData
        ? moment(postData.dateOfPublish).utc().format("YYYY-MM-DDTHH:mm")
        : moment().format("YYYY-MM-DDTHH:mm"),
      type: postData ? postData.type : "social",
      academicYear: postData ? postData.academicYear : getCurrentAcademicYear(),
      id: postData ? postData.id : null,
      mediaName: postData ? postData.mediaName: "",
      externalLink: postData ? postData.externalLink: ""
    });
    setImgData(postData ? postData.mediaName : "");
    setPicture(null);
  }, [postData]);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setError(null);
  };

  const { id, content, dateOfPublish, type, academicYear, externalLink } = formData;

  const onSubmit = (e) => {
    if (moment().add(1, 'hours') >= moment(dateOfPublish)) {
      setError("Date invalide (veuillez choisir une date supérieur à l'actuel d'au moins 1 heure!)");
      return;
    }
    if (content.length === 0 && picture === null && imgData === "") {
      setError("Le contenu du post ou l'image sont obligatoires");
      return;
    }
    if (type === "sponsor" && externalLink === "") {
      setError("Le lien externe est obligatoire pour les posts sponsorisés");
      return;
    }
    e.preventDefault();
    if (id) {
      if (picture === null) {
        updatePost(formData, picture);
      } else {
        uploadFiles(formData, picture);
      }
    } else {
      uploadFiles(formData, picture);
      setFormData({
        content: "",
        dateOfPublish: "",
        type: "social",
        academicYear: "",
        id: null,
        mediaName: "",
        externalLink: ""
      })
      setImgData(null)
      setPicture(null);
    }
  };

  const [picture, setPicture] = useState(null);
  const [imgData, setImgData] = useState(null);
  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      setPicture(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
    setError(null);
  };

  return (
    <>
      <p className="addAdminFormTitle">{id ? disableForm? "Poste publié" : "Poste" : "Nouveau poste"}</p>
      <div className="formSection">
        <label className="academicCheckboxLabel">Date de publication</label>
        <input
          disabled={!id?false:disableForm}
          type="datetime-local"
          style={{
            minHeight: "4rem",
          }}
          className="form-control"
          name="dateOfPublish"
          min={moment().format("YYYY-MM-DDTHH:mm")}
          value={dateOfPublish}
          onChange={(e) => onChange(e)}
        />
        <label className="academicCheckboxLabel">Type du post</label>

        <select
          name="type"
          value={type}
          onChange={(e) => onChange(e)}
        >
          <option value="social">Social</option>
          <option value="bio">Biologique</option>
          <option value="psycho">Psycho-affectif</option>
          <option value="sponsor">Sponsorisé</option>
          <option value="intro">Présentation</option>
        </select>
        <label className="academicCheckboxLabel">Année scolaire</label>
        <select
          disabled={!id?false:disableForm}
          name="academicYear"
          value={academicYear}
          onChange={(e) => onChange(e)}
        >
          {academicYears &&
            academicYears.map((academicYear) => (
              <option key={academicYear.name} value={academicYear.name}>
                {academicYear.name}
              </option>
            ))}
        </select>
        <label className="academicCheckboxLabel">Contenu du post</label>
        <textarea
          name="content"
          value={content}
          rows="5"
          cols="33"
          onChange={(e) => onChange(e)}
        ></textarea>
        {type === "sponsor" &&
          <>
            <label className="academicCheckboxLabel">Lien externe</label>
            <input
              name="externalLink"
              value={externalLink}
              onChange={(e) => onChange(e)}
            ></input>
          </>
        }
        <label
          htmlFor="postUpload"
          className="postUploadLabel"
        >
          <p>Charger une image</p>
        </label>
        <input
          className="postUpload"
          id="postUpload"
          type="file"
          onChange={(e) => onChangePicture(e)}
        />
        {imgData && (
          <img className="postPreviewimg" src={imgData} alt="uploaded img" />
        )}
        <p className="error-msg">{error}</p>
      </div>
      {id ? (
        <div className="adminsFormBtnContainer">
          <button onClick={onSubmit} className="Btn">
            Modifier
          </button>
          <button onClick={openConversationSection} className="Btn">
            Conversation
          </button>
        </div>
      ) : (
        <div className="adminsFormBtnContainer">
          <button onClick={onSubmit} className="Btn">
            Enregistrer
          </button>
        </div>
      )}
    </>
  );
};

PostForm.propTypes = {
  uploadFiles: PropTypes.func.isRequired,
  addPost: PropTypes.func.isRequired,
  updatePost: PropTypes.func.isRequired,
  openConversationSection: PropTypes.func.isRequired,
};

export default connect(null, { uploadFiles, addPost, updatePost })(PostForm);
