import React from "react";
import { withRouter } from "react-router-dom";

function NotFound({ history }) {
  return (
    <div className="section-not-found">
      <h3>Cette page n'existe pas.</h3>
      <button onClick={() => history.push("/login")} className="Btn">
        Retourner a la page principale
      </button>
    </div>
  );
}

export default withRouter(NotFound);
