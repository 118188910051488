import React, { useCallback, useEffect, useState } from "react";
import "./establishmentSections.scss";
import EstablishmentsListItem from "./EstablishmentsListItem";
import EstablishmentForm from "./EstablishmentForm";
import Pagination from "../layout/Pagination";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

import { getEstablishmentsList as getEstablishmentsListAction } from "../../actions/establishment";
import { useActions } from "../../utils/useActions.hook";

const establishmentSelector = (state) => ({
  establishments: state.establishments.establishments,
  loading: state.establishments.loading,
});

const EstablishmentSection = () => {
  const { establishments, loading } = useSelector(establishmentSelector);
  const getEstablishmentsList = useActions(getEstablishmentsListAction);

  const LIMIT_SIZE = 5;
  const [establishmentData, changeEstablishmentData] = useState();
  const [selectedElement, changeSelectedElement] = useState();
  const [pageNumber, changePageNumber] = useState(1);

  useEffect(() => {
    getEstablishmentsList(pageNumber, LIMIT_SIZE);
  }, [getEstablishmentsList, pageNumber]);

  const openeEtablishmentsCreationForm = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeEstablishmentData(null);
    changeSelectedElement(null);
  };
  const displayEstablishmentData = useCallback((data) => {
    changeSelectedElement(data.id);
    changeEstablishmentData({
      name: data.name,
      type: data.type,
      postalCode: data.postalCode,
      userGender: data.userGender,
      userFirstName: data.userFirstName,
      userLastName: data.userLastName,
      email: data.email,
      isArchived: data.isArchived,
      code: data.code,
      id: data.id,
    });
  }, []);

  const onChangePageNumber = (number) => {
    changePageNumber(number);
  };

  useEffect(() => {
    if (!loading && establishments.items && establishments.items.length > 0) {
      displayEstablishmentData(establishments.items[0]);
    }
  }, [loading, displayEstablishmentData, establishments.items]);

  return (
    <div className="adminsSectiondMainContainer">
      <div className="adminsListContainer">
        {establishments &&
          establishments.items &&
          establishments.items.map((establishment) => (
            <EstablishmentsListItem
              key={establishment.id}
              displayEstablishmentData={displayEstablishmentData}
              establishmentData={establishment}
              isSelected={selectedElement === establishment.id}
            />
          ))}
        <Pagination
          totalPages={
            establishments && establishments.meta
              ? establishments.meta.totalPages
              : 1
          }
          page_number={pageNumber}
          onChangePageNumber={onChangePageNumber}
        />
        <div className="adminsSectionBtnContainer">
          <button onClick={openeEtablishmentsCreationForm} className="Btn">
            +
          </button>
        </div>
      </div>
      <div className="adminsFormContainer">
        <EstablishmentForm establishmentData={establishmentData} />
      </div>
    </div>
  );
};

EstablishmentSection.propTypes = {
  getEstablishmentsList: PropTypes.func.isRequired,
};

export default EstablishmentSection;
