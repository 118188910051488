import React from "react";
import { withRouter } from "react-router-dom";
import EstablishmentIcon from "../../assets/images/establishment-icon.png";
const EstablishmentsListItem = ({
  displayEstablishmentData,
  establishmentData,
  isSelected,
}) => {
  return (
    <div
      onClick={() => displayEstablishmentData(establishmentData)}
      className={
        isSelected
          ? "adminListitemMainContainer-active"
          : "adminListitemMainContainer"
      }
    >
      <img
        className="adminListitemImage"
        src={EstablishmentIcon}
        alt="admin"
      />
      <div className="establishmenttitemTextContainer">
        <p className="adminListitemName">{establishmentData.name}</p>
        <p className="adminListitemRole">
          Paris {establishmentData.postalCode}/ code : {establishmentData.code}
        </p>
      </div>
      {establishmentData.isArchived && (
        <p className="establishmentState">
        Archivé
        </p>
      )}
    </div>
  );
};

export default withRouter(EstablishmentsListItem);
