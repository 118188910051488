import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { addAcademicYear, updateAcademicYear } from "../../actions/academic";
import { getCurrentAcademicYear } from "../../utils/moment";
const AcademicYearForm = ({
  academicYearData,
  addAcademicYear,
  updateAcademicYear,
}) => {
  const [formData, setFormData] = useState({
    name: "",
    isCurrent: false,
    id: null,
  });
  const [error, setError] = useState(null);
  useEffect(() => {
    setFormData({
      name: academicYearData ? academicYearData.name : "",
      isCurrent: academicYearData ? academicYearData.isCurrent : false,
      id: academicYearData ? academicYearData.id : null,
    });
  }, [academicYearData]);

  const { id, name, isCurrent } = formData;
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setError(null);
  };

  const onChangeCheckbox = (e) => {
    setFormData({ ...formData, isCurrent: !isCurrent });
    setError(null);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (id) {
      updateAcademicYear(formData);
    } else {
      addAcademicYear(formData);
      setFormData({
        name: "",
        isCurrent: false,
        id: null,
      });
    }
  };

  return (
    <>
      <p className="addAdminFormTitle">
        {id ? "Année scolaire" : "Ajouter année scolaire"}
      </p>
      <div className="formSection">
        <input
          type="text"
          className="form-control"
          name="name"
          placeholder={"Année scolaire exemple: " + getCurrentAcademicYear()}
          value={name}
          onChange={(e) => onChange(e)}
        />
        <div className="academicCheckboxContainer">
          <input
            className="academicCheckbox"
            type="checkbox"
            id="isCurrent"
            name="isCurrent"
            value={isCurrent}
            onChange={(e) => onChangeCheckbox(e)}
            checked={isCurrent}
          />
          <label htmlFor="isCurrent" className="academicCheckboxLabel">
            Année courante
          </label>
        </div>

        <p className="error-msg">{error}</p>
      </div>
      <div className="adminsFormBtnContainer">
        {id ? (
          <button onClick={onSubmit} className="Btn">
            Modifier
          </button>
        ) : (
          <button onClick={onSubmit} className="Btn">
            Ajouter
          </button>
        )}
      </div>
    </>
  );
};

AcademicYearForm.propTypes = {
  addAcademicYear: PropTypes.func.isRequired,
  updateAcademicYear: PropTypes.func.isRequired,
};

export default connect(null, { addAcademicYear, updateAcademicYear })(
  AcademicYearForm
);
