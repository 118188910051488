import React, { useState, useEffect, useCallback } from "react";
import "./QuestionBoxSection.scss";
import QuestionBoxListItem from "./QuestionBoxListItem";
import QuestionForm from "./QuestionForm";
import { getQuestionsBoxList } from "../../actions/questionBox";
import ReactExport from "react-data-export";
import Pagination from "../layout/Pagination";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";

const QuestionsBoxSection = ({
  getQuestionsBoxList,
  questions,
  allQuestion,
}) => {
  const LIMIT_SIZE = 5;

  const [questionList, setQuestionList] = useState([""]);
  const [questionData, changeQuestionData] = useState();
  const [selectedElement, changeSelectedElement] = useState();
  const [pageNumber, changePageNumber] = useState(1);
  const [formData, setFormData] = useState({
    startDate: null,
    endDate: null,
    seenState: "tous",
  });
  const { startDate, endDate, seenState } = formData;

  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  useEffect(() => {
    getQuestionsBoxList(pageNumber, LIMIT_SIZE, startDate, endDate, seenState);
  }, [getQuestionsBoxList, pageNumber, startDate, endDate, seenState]);

  useEffect(() => {
    if (allQuestion && allQuestion.items && allQuestion.items.length > 0)
      setQuestionList(allQuestion.items);
  }, [allQuestion, setQuestionList]);

  const displayQuestionData = useCallback(
    (data) => {
      changeSelectedElement(data.id);
      changeQuestionData({
        askedBy: data.askedBy,
        isSeen: data.isSeen,
        question: data.question,
        id: data.id,
      });
    },
    [questions]
  );

  const onChange = (e) => {
    if (e.target.name === "seenState") {
      changePageNumber(1);
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onChangePageNumber = (number) => {
    changePageNumber(number);
  };

  // useEffect(() => {
  //   if (!loading && questions.items.length > 0) {
  //     displayQuestionData(questions.items[0]);
  //   }
  // }, [loading]);

  return (
    <div className="adminsSectiondMainContainer">
      <div
        style={{
          minWidth: "35%",
          height: "80vh" 
        }}
        className="adminsListContainer"
      >
        <div className="inputsContainer">
          <select
            style={{
              minHeight: "4rem",
              margin: "2rem",
            }}
            name="seenState"
            value={seenState}
            onChange={(e) => onChange(e)}
          >
            <option value="tous">Tous</option>
            <option value="nouveau">Non traité</option>
            <option value="vu">Traité</option>
          </select>
          <label htmlFor="startdatetime" className="academicCheckboxLabel">
            Date début
          </label>
          <input
            id="startdatetime"
            type="date"
            style={{
              minHeight: "4rem",
              margin: "2rem",
            }}
            className="form-control"
            name="startDate"
            value={startDate}
            onChange={(e) => onChange(e)}
          />
          <label htmlFor="enddatetime" className="academicCheckboxLabel">
            Date fin
          </label>
          <input
            id="enddatetime"
            type="date"
            style={{
              minHeight: "4rem",
              margin: "2rem",
            }}
            className="form-control"
            name="endDate"
            value={endDate}
            onChange={(e) => onChange(e)}
          />
        </div>
        <div className="inputsContainer">
          <ExcelFile
            filename={`Boite à questions ${moment().format()}`}
            element={<button className="Btn">Télécharger</button>}
          >
            <ExcelSheet data={questionList} name="boite à questions">
              <ExcelColumn
                label="Code etablissement"
                value={(col) => col.askedBy.establishment.code}
              />
              <ExcelColumn
                label="Etablissement"
                value={(col) => col.askedBy.establishment.name}
              />
              <ExcelColumn label="Question" value="question" />
              <ExcelColumn
                label="Age"
                value={(col) => moment().diff(col.askedBy.dateOfBirth, "years")}
              />
              <ExcelColumn label="Date de creation" value="createdAt" />
              <ExcelColumn
                label="Etat"
                value={(col) => (col.isSeen ? "Traité" : "Non traité")}
              />
            </ExcelSheet>
          </ExcelFile>
        </div>

        {questions &&
          questions.items &&
          questions.items.map((question) => (
            <QuestionBoxListItem
              key={question.id}
              displayQuestionData={displayQuestionData}
              questionData={question}
              isSelected={selectedElement === question.id}
            />
          ))}
        <Pagination
          totalPages={
            questions && questions.meta ? questions.meta.totalPages : 1
          }
          page_number={pageNumber}
          onChangePageNumber={onChangePageNumber}
        />
      </div>
      <div className="questionFormContainer">
        <QuestionForm questionData={questionData} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  questions: state.questionbox.questions,
  allQuestion: state.questionbox.allquestions,
  loading: state.questionbox.loading,
});

QuestionsBoxSection.propTypes = {
  getQuestionsBoxList: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  getQuestionsBoxList,
})(QuestionsBoxSection);
