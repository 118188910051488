import React from "react";
import SpeachLogo from "../../assets/images/avatarSpeach.svg";
import "./navBar.scss";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logout } from "../../actions/auth";
import { withRouter } from "react-router-dom";

const Navbar = ({ history, logout, isAuthenticated, user }) => {
  const logOutHandler = (e) => {
    e.preventDefault();
    logout();
  };
  return (
    isAuthenticated && (
        <div className="navMainContainer">
          {history.location.pathname !== "/dashboard" && (
            <div className="currentLocation">
              <p onClick={()=>history.push("/dashboard")} className="navAdminName">
                <i className="arrow left"></i>Retour
              </p>
            </div>
          )}

          <div onClick={() => history.push("/")} className="navTitleSection">
            <img className="mainImage" alt="speach logo" src={SpeachLogo} />
            <p className="mainTitle">Hello Speach</p>
          </div>
          <div className="infosSection">
            <p className="navAdminName">
              {user.firstName + " " + user.lastName}
            </p>
            <button onClick={logOutHandler} className="Btn">
              Déconnexion
            </button>
          </div>
        </div>
    )
  );
};

Navbar.propTypes = {
  logout: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});
export default withRouter(
  connect(mapStateToProps, {
    logout,
  })(Navbar)
);
