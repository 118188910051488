import { Integrations } from "@sentry/tracing";
import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom";
import React from "react";

import { Environments } from "./utils/constants";
import reportWebVitals from "./reportWebVitals";
import App from "./App";

const environment = process.env.REACT_APP_NODE_ENV;

if (environment !== Environments.Development) {
  Sentry.init({
    integrations: [new Integrations.BrowserTracing()],
    dsn: process.env.REACT_APP_SP_BO_SENTRY_DNS,
    environment: environment,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
