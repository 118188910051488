import {
  QUESTION_BOX_ERROR,
  QUESTION_BOX_LIST,
  UPDATE_QUESTION_BOX,
  QUESTION_BOX_ALL_LIST
} from "../actions/types";

const initialState = {
  loading: true,
  questions: [],
  allquestions:[],
  error: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case UPDATE_QUESTION_BOX: {
      return {
        ...state,
        questions: {
          ...state.questions,
          items: state.questions.items.map((question) => {
            if (question.id === payload) {
              question.isSeen = !question.isSeen;
            }
            return question;
          }),
        },
        loading: false,
      };
    }
    case QUESTION_BOX_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case QUESTION_BOX_LIST: {
      return {
        ...state,
        questions: payload,
        loading: false,
      };
    }
    case QUESTION_BOX_ALL_LIST: {
      return {
        ...state,
        allquestions: payload,
        loading: false,
      };
    }
    default:
      return state;
  }
}
