import axios from "axios";

import {
  UPLOAD_FILE,
  ADD_STORY,
  DELETE_STORY,
  UPDATE_STORY,
  STORIES_LIST,
  STORY_ERROR,
} from "./types";
import { setAlert } from "./alert";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

// Delete story
export const deleteStory = (storyID) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    await axios.delete(`${BASE_URL}/stories/${storyID}`, config);

    dispatch({
      type: DELETE_STORY,
      payload: storyID,
    });
    dispatch(setAlert("Story supprimer", "success"));
  } catch (err) {
    dispatch({
      type: STORY_ERROR,
      payload: err.response.data.message,
    });
    dispatch(
      setAlert(`Echec de l'envoi: ${err.response.data.message}`, "danger")
    );
  }
};

// Get stories list
export const getStoriesList = (page, limit) => async (dispatch) => {
  const config = {
    headers: {
      "app-domain": "backoffice",
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(
      `${BASE_URL}/stories/?page=${page}&limit=${limit}`,
      config
    );

    dispatch({
      type: STORIES_LIST,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: STORY_ERROR,
      payload: err.response.data.message,
    });
    dispatch(
      setAlert(`Echec de l'envoi: ${err.response.data.message}`, "danger")
    );
  }
};

// File upload
export const uploadFiles = (formInfos, file) => async (dispatch) => {
  let formData = new FormData();
  formData.append("file", file);
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  try {
    const res = await axios.post(`${BASE_URL}/upload`, formData, config);
    dispatch({
      type: UPLOAD_FILE,
      payload: res.data,
    });
    if (formInfos.id) {
      dispatch(updateStory(formInfos, res.data));
    } else {
      dispatch(addStory(formInfos, res.data));
    }
    dispatch(setAlert("Story Ajoutée", "success"));
  } catch (err) {
    dispatch({
      type: STORY_ERROR,
      payload: err.response.data.message,
    });
    dispatch(
      setAlert(`Echec de l'envoi: ${err.response.data.message}`, "danger")
    );
  }
};

// Update story
export const updateStory = (formData, imgData) => async (dispatch) => {
  const config = {
    headers: {
      "app-domain": "backoffice",
      "Content-Type": "application/json",
    },
  };
  if (imgData) {
    formData["mediaName"] = imgData.filename;
  }
  const body = JSON.stringify(formData);

  try {
    const res = await axios.patch(
      `${BASE_URL}/stories/${formData.id}`,
      body,
      config
    );
    dispatch({
      type: UPDATE_STORY,
      payload: res.data,
    });
    dispatch(setAlert("Story Ajoutée", "success"));
  } catch (err) {
    dispatch({
      type: STORY_ERROR,
      payload: err.response.data.message,
    });
    dispatch(
      setAlert(`Echec de l'envoi: ${err.response.data.message}`, "danger")
    );
  }
};

// Add story
export const addStory = (formData, imgData) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  if (imgData) {
    formData["mediaName"] = imgData.filename;
  }

  const body = JSON.stringify(formData);

  try {
    const res = await axios.post(`${BASE_URL}/stories`, body, config);
    dispatch({
      type: ADD_STORY,
      payload: res.data,
    });
    dispatch(setAlert("Story Ajoutée", "success"));
  } catch (err) {
    dispatch({
      type: STORY_ERROR,
      payload: err.response.data.message,
    });
    dispatch(
      setAlert(`Echec de l'envoi: ${err.response.data.message}`, "danger")
    );
  }
};
