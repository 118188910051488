import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { uploadFiles, deleteStory, updateStory } from "../../actions/story";

const StoryForm = ({ deleteStory, uploadFiles, updateStory, storyData }) => {
  const [formData, setFormData] = useState({
    type: "temporary",
    postType: "social",
    attachedUrl: null,
    id: null,
  });
  const [picture, setPicture] = useState(null);
  const [imgData, setImgData] = useState(null);

  const [error, setError] = useState(null);
  useEffect(() => {
    setFormData({
      createdAt: storyData ? storyData.createdAt : "",
      updatedAt: storyData ? storyData.updatedAt : "",
      id: storyData ? storyData.id : null,
      postType: storyData ? storyData.postType : "social",
      vuesCounter: storyData ? storyData.vuesCounter : "",
      type: storyData ? storyData.type : "temporary",
      attachedUrl: storyData ? storyData.attachedUrl : "",
      mediaName: storyData ? storyData.mediaName : null,
    });
    setImgData(storyData ? storyData.mediaName : null);
    setPicture(null);
  }, [storyData]);

  const { id, type, postType, attachedUrl } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setError(null);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    uploadFiles(formData, picture);
  };

  const onUpdate = (e) => {
    e.preventDefault();
    if (picture === null) {
      updateStory(formData, picture);
    } else {
      uploadFiles(formData, picture);
    }
  };

  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      setPicture(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgData(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
    setError(null);
  };

  return (
    <>
      <p className="addAdminFormTitle">{id ? "Story" : "Nouvelle story"}</p>
      <div className="formSection">
        <label className="storiesCheckboxLabel">Type de la story</label>
        <select name="type" value={type} onChange={(e) => onChange(e)}>
          <option value="temporary">Story temporaire</option>
          <option value="permanent">Story permanente</option>
        </select>
        {type !== "temporary" && (
          <>
            <label className="storiesCheckboxLabel">
              Catégorie de la story
            </label>
            <select
              name="postType"
              value={postType}
              onChange={(e) => onChange(e)}
            >
              <option value="intro">Intro</option>
              <option value="social">Social</option>
              <option value="bio">Biologique</option>
              <option value="psycho">Psycho-affectif</option>
            </select>
          </>
        )}

        <label className="storiesCheckboxLabel">Lien externe</label>
        <input
          style={{
            minHeight: "4rem",
          }}
          type="text"
          className="form-control"
          name="attachedUrl"
          placeholder="Ajouter un lien (optionnel)"
          value={attachedUrl}
          onChange={(e) => onChange(e)}
        />
        <label className="storiesCheckboxLabel">Charger l'image </label>
        <input id="profilePic" type="file" onChange={onChangePicture} />
        {imgData && (
          <img className="storiePreviewimg" src={imgData} alt="uploaded img" />
        )}
        <p className="error-msg">{error}</p>
      </div>
      <div
        style={{
          paddingBottom: "10rem",
        }}
        className="adminsFormBtnContainer"
      >
        {id ? (
          <>
            <button onClick={onUpdate} className="Btn">
              Modifier
            </button>
            <button onClick={() => deleteStory(id)} className="Btn">
              Supprimer
            </button>
          </>
        ) : (
          <button onClick={onSubmit} className="Btn">
            Créer la Story
          </button>
        )}
      </div>
    </>
  );
};

StoryForm.propTypes = {
  uploadFiles: PropTypes.func.isRequired,
  deleteStory: PropTypes.func.isRequired,
  updateStory: PropTypes.func.isRequired,
};

export default connect(null, { uploadFiles, deleteStory, updateStory })(
  StoryForm
);
