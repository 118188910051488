import React from "react";
import { withRouter } from "react-router-dom";
import placeHolder from "../../assets/images/user-placeholder.png";
const AdminsListItem = ({ displayAdminData, adminData, isSelected }) => {
  return (
    <div
      onClick={() => displayAdminData(adminData)}
      className={
        isSelected
          ? "adminListitemMainContainer-active"
          : "adminListitemMainContainer"
      }
    >
      <div className="adminListitemTextContainer">
        <p className="adminListitemName">
          {adminData.firstName + " " + adminData.lastName}
        </p>
        <p className="adminListitemRole">{adminData.role}</p>
      </div>
      <p className="adminsState">
        {adminData.hasConfirmedAccount ? "Activé" : "En attente"}
      </p>
    </div>
  );
};

export default withRouter(AdminsListItem);
