import { STATS_LIST, STATS_ERROR } from "../actions/types";

const initialState = {
  loading: true,
  stats: [],
  error: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case STATS_LIST: {
      return {
        stats: payload,
        loading: false,
      };
    }

    case STATS_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };

    default:
      return state;
  }
}
