import { combineReducers } from "redux";

import auth from "./auth";
import admins from "./admins";
import establishments from "./establishment";
import academicYears from "./academic";
import posts from "./posts";
import students from "./students";
import conversations from "./conversation";
import statistics from "./statistics";
import story from "./story";
import questionbox from "./questionBox";
import alert from "./alert";

const appReducer = combineReducers({
  auth,
  admins,
  establishments,
  academicYears,
  posts,
  students,
  conversations,
  statistics,
  story,
  questionbox,
  alert,
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
