import axios from "axios";

import {
  STUDENTS_ERROR,
  STUDENTS_LIST,
  UPDATE_STUDENT,
  ARCHIVE_STUDENT,
} from "./types";
import { setAlert } from "./alert";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

//Get students List
export const getStudentsList = (page, limit, id) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const res = await axios.get(
      `${BASE_URL}/users/?page=${page}&limit=${limit}&role=student&establishmentId=${id}`,
      config
    );

    dispatch({
      type: STUDENTS_LIST,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: STUDENTS_ERROR,
      payload: err.response.data.message,
    });
    dispatch(
      setAlert(`Echec de l'envoi: ${err.response.data.message}`, "danger")
    );
  }
};

//update student

export const updateStudent = (formData, type) => async (dispatch) => {
  const config = {
    headers: {
      "app-domain": "backoffice",
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify(formData);

  try {
    const res = await axios.patch(
      `${BASE_URL}/users/${formData.id}`,
      body,
      config
    );
    if (type === "suspending") {
      dispatch({
        type: UPDATE_STUDENT,
        payload: res.data,
      });

      dispatch(setAlert("l'élève à été mis à jour", "success"));
    } else if (type === "archiving") {
      dispatch({
        type: ARCHIVE_STUDENT,
        payload: formData.id,
      });
      dispatch(setAlert("l'élève à été archiver", "success"));
    }
  } catch (err) {
    dispatch({
      type: STUDENTS_ERROR,
      payload: err.response.data.message,
    });
    dispatch(
      setAlert(`Echec de l'envoi: ${err.response.data.message}`, "danger")
    );
  }
};
