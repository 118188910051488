import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

const ConversationQR = ({
  qrname,
  parent,
  quickReplyHandler,
  deleteBubble,
  conversations,
  qrData,
  disableForm,
}) => {
  const [formData, setFormData] = useState({
    content: "",
    next: "",
    id: null,
    name: qrname,
    qrsList: [],
  });
  const { content, id, name, next, qrsList } = formData;

  useEffect(() => {
    const filtredArray =
      conversations &&
      conversations.filter((conversation) => {
        if (!conversation.isRoot && parent !== conversation.name) {
          return conversation;
        }
      });
    setFormData({
      ...formData,
      qrsList: filtredArray,
      content: qrData ? qrData.content : "",
      id: qrData ? qrData.id : null,
      next: qrData
        ? qrData.nextQuestion
          ? qrData.nextQuestion.id
          : filtredArray.length > 0
          ? filtredArray[0].id
          : ""
        : "",
    });
  }, [qrData.content]);

  const onChange = (e) => {
    const { value } = e.target;

    setFormData({ ...formData, [e.target.name]: value });
    quickReplyHandler({
      name,
      parent,
      content: e.target.name === "content" ? value : content,
      id,
      order: 0,
      next: e.target.name === "next" ? value : next,
    });
  };
  return (
    <div
      style={{
        width: "20%",
        height: "50%",
      }}
    >
      <input
        type="text"
        className="discutionQRElement"
        name="content"
        value={content}
        maxLength="25"
        onChange={(e) => onChange(e)}
      />
      <div className="discutionSelectContainer">
        <label
          style={{
            opacity: 1,
            color: "#000",
          }}
          className="discutionSelectLabel"
        >
          Bloc associé:
        </label>
        <select
          style={{
            width: "50%",
          }}
          value={next}
          name="next"
          onChange={(e) => onChange(e)}
        >
          {qrsList.map((qr) => (
            <option key={qr.name} value={qr.id}>
              {qr.name}
            </option>
          ))}
        </select>
      </div>

      <div>
        <p className="contentLength">{content.length}/25</p>
        {!disableForm && (
          <p
            className="deleteDiscutionQRElement"
            onClick={() => deleteBubble(name, id)}
          >
            Supprimer
          </p>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  conversations: state.conversations.questions,
});

export default connect(mapStateToProps, {})(ConversationQR);
