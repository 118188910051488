import axios from "axios";

import {
  QUESTION_BOX_ERROR,
  QUESTION_BOX_LIST,
  UPDATE_QUESTION_BOX,
  QUESTION_BOX_ALL_LIST,
} from "./types";
import { setAlert } from "./alert";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

//Get questions List
export const getQuestionsBoxList =
  (page, limit, startDate, endDate, seenState) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const isSeen =
      seenState === "tous" ? null : seenState === "vu" ? true : false;
    try {
      const res = await axios.get(
        `${BASE_URL}/stories/questions/?page=${page}&limit=${limit}${
          endDate != null ? `&to=${endDate}` : ""
        }${startDate != null ? `&from=${startDate}` : ""}${
          isSeen != null ? `&isSeen=${isSeen}` : ""
        }`,
        config
      );

      dispatch({
        type: QUESTION_BOX_LIST,
        payload: res.data,
      });
      dispatch(
        getAllQuestionsBoxList(
          page,
          res.data.meta.totalItems,
          startDate,
          endDate,
          seenState
        )
      );
    } catch (err) {
      dispatch({
        type: QUESTION_BOX_ERROR,
        payload: err.response.data.message,
      });
      dispatch(
        setAlert(`Echec de l'envoi: ${err.response.data.message}`, "danger")
      );
    }
  };

//Get all questions List
export const getAllQuestionsBoxList =
  (page, limit, startDate, endDate, seenState) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const isSeen =
      seenState === "tous" ? null : seenState === "vu" ? true : false;
    try {
      const res = await axios.get(
        `${BASE_URL}/stories/questions/?page=${page}&limit=${limit}${
          endDate != null ? `&to=${endDate}` : ""
        }${startDate != null ? `&from=${startDate}` : ""}${
          isSeen != null ? `&isSeen=${isSeen}` : ""
        }`,
        config
      );

      dispatch({
        type: QUESTION_BOX_ALL_LIST,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: QUESTION_BOX_ERROR,
        payload: err.response.data.message,
      });
      dispatch(
        setAlert(`Echec de l'envoi: ${err.response.data.message}`, "danger")
      );
    }
  };

//update question

export const updateQuestionsBox = (id, isSeen) => async (dispatch) => {
  const config = {
    headers: {
      "app-domain": "backoffice",
      "Content-Type": "application/json",
    },
  };
  try {
    await axios.patch(
      `${BASE_URL}/stories/questions/${id}?isSeen=${isSeen}`,
      config
    );
    dispatch({
      type: UPDATE_QUESTION_BOX,
      payload: id,
    });
  } catch (err) {
    dispatch({
      type: QUESTION_BOX_ERROR,
      payload: err.response.data.message,
    });
    dispatch(
      setAlert(`Echec de l'envoi: ${err.response.data.message}`, "danger")
    );
  }
};
