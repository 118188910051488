import axios from "axios";

import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  FORGET_PASSWORD,
  FORGET_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_ERROR,
  CLEAR_ERROR,
} from "./types";
import { setAlert } from "./alert";
import setAuthToken from "../utils/setAuthToken";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

// Login User
export const login = (email, password) => async (dispatch) => {
  const config = {
    headers: {
      "app-domain": "backoffice",
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ email, password });

  try {
    const res = await axios.post(`${BASE_URL}/users/sign-in`, body, config);

    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });

    dispatch(loadUser());
  } catch (err) {
    dispatch({
      type: LOGIN_FAIL,
    });
    dispatch(
      setAlert(`Echec: Verifier votre e-mail ou mot de passe`, "danger")
    );
  }
};

// Load User
export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  const config = {
    headers: {
      "Content-Type": "application/json",
      Vary: "*",
      "Cache-Control":
        "no-cache, no-store, must-revalidate, post-check=0, pre-check=0",
      Pragma: "no-cache",
      Expires: "0",
    },
  };
  try {
    const res = await axios.get(`${BASE_URL}/users/me`, config);
    dispatch({
      type: USER_LOADED,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
    });
  }
};

// Register User
export const setPassword =
  (password, invitationToken, history) => async (dispatch) => {
    const config = {
      headers: {
        "app-domain": "backoffice",
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ password, invitationToken });

    try {
      const res = await axios.patch(
        `${BASE_URL}/users/create-admin`,
        body,
        config
      );

      dispatch({
        type: REGISTER_SUCCESS,
        payload: res.data,
      });
      dispatch(setAlert("Compte crée avec succées", "success"));
      dispatch(clearError());
      history.push("/login");
    } catch (err) {
      const errors = err.response.data.message;
      dispatch({
        type: REGISTER_FAIL,
        payload: errors,
      });
      dispatch(
        setAlert(`Echec de l'envoi: ${err.response.data.message}`, "danger")
      );
    }
  };

// Logout
export const logout = () => (dispatch) => {
  setAuthToken();
  dispatch({ type: LOGOUT });
};

// forget password
export const forgetPassword = (email) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let data = {
      email,
    };

    const res = await axios.post(
      `${BASE_URL}/api/forgetPassword`,
      data,
      config
    );

    dispatch({
      type: FORGET_PASSWORD,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: FORGET_PASSWORD_ERROR,
      payload: { msg: err.response.data.message },
    });
  }
};

// reset password
export const resetPassword =
  (email, password, passwordConfirmationToken) => async (dispatch) => {
    try {
      const config = {
        headers: {
          "app-domain": "backoffice",
          "Content-Type": "application/json",
        },
      };
      const body = JSON.stringify({
        email,
        password,
        passwordConfirmationToken,
      });

      const res = await axios.patch(
        `${BASE_URL}/users/reset-password`,
        body,
        config
      );
      localStorage.setItem("token", res.data.token);
      dispatch(loadUser());
      dispatch({
        type: RESET_PASSWORD,
      });
      dispatch(
        setAlert(
          password
            ? "votre mot de passe a été réinitialisé"
            : "Email envoyée ... Verifiez votre boite mail .",
          "success"
        )
      );
    } catch (err) {
      dispatch({
        type: RESET_PASSWORD_ERROR,
        payload: { msg: err.response.data.message },
      });
      dispatch(
        setAlert(`Echec de l'envoi: ${err.response.data.message}`, "danger")
      );
    }
  };

// sent reset password token
export const resetPasswordEmail = (email) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "app-domain": "backoffice",
        "Content-Type": "application/json",
      },
    };

    await axios.patch(`${BASE_URL}/users/reset-password`, { email }, config);

    dispatch({
      type: RESET_PASSWORD,
    });
    dispatch(
      setAlert("Email envoyée ... Verifiez votre boite mail .", "success")
    );
  } catch (err) {
    dispatch({
      type: RESET_PASSWORD_ERROR,
      payload: { msg: err.response.data.message },
    });
    dispatch(
      setAlert(`Echec de l'envoi: ${err.response.data.message}`, "danger")
    );
  }
};

//clear errors
export const clearError = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ERROR,
  });
};
