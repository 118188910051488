import React, { useState, useEffect, useCallback } from "react";
import "./programSections.scss";
import PostsListItem from "./PostsListItem";
import PostForm from "./PostForm";
import PostDisplay from "./PostDisplay";
import AddDiscution from "./AddDiscution";
import ConversationBlocs from "./ConversationBloc";
import { getAcademicYearList } from "../../actions/academic";
import moment from "moment";

import { getPostsList } from "../../actions/posts";

import Pagination from "../layout/Pagination";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const PostsSection = ({
  posts,
  conversations,
  academicYears,
  getPostsList,
  getAcademicYearList,
  loading,
}) => {
  const LIMIT_SIZE = 5;

  const [disableForm, setDisableForm] = useState(false);
  const [postData, changePostData] = useState();
  const [conversationData, changeConversationData] = useState();
  const [conversationSectionState, changeConversationSectionState] = useState(
    false
  );

  const [selectedElement, changeSelectedElement] = useState();
  const [selectedBloc, changeSelectedBloc] = useState();
  const [pageNumber, changePageNumber] = useState(1);

  useEffect(() => {
    getAcademicYearList(1, 100);
    getPostsList(pageNumber, LIMIT_SIZE);
  }, [getAcademicYearList, getPostsList, pageNumber]);

  const openPostCreationForm = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changePostData(null);
    changeSelectedElement(null);
    changeConversationSectionState(false);
  };

  const openConversationSection = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    changeConversationSectionState(true);
  };

  const displayPostData = useCallback((data) => {
    if (moment().add(1, 'hours') >= moment(data.dateOfPublish)) {
      setDisableForm(true);
    } else {
      setDisableForm(false);
    }
    changeConversationSectionState(false);
    changeSelectedElement(data.id);
    changePostData({
      content: data.content,
      isArchived: data.isArchived,
      dateOfPublish: data.dateOfPublish,
      type: data.type,
      id: data.id,
      mediaName: data.mediaName,
      externalLink: data.externalLink
    });
  }, []);

  const displayConversationData = useCallback((data) => {
    if (data == null) {
      changeConversationData();
    } else {
      changeSelectedBloc(data.id);
      changeConversationData({
        content: data.content,
        name: data.name,
        level: data.level,
        id: data.id,
        isRoot: data.isRoot,
        quickReplies: data.quickReplies,
      });
    }
  }, []);

  useEffect(() => {
    if (!loading && posts.items.length > 0) {
      displayPostData(posts.items[0]);
    }
  }, [loading, displayPostData, posts.items]);

  const onChangePageNumber = (number) => {
    changePageNumber(number);
  };
  const createBloc = () => {
    changeSelectedBloc(null);
    changeConversationData({
      content: "",
      name: "",
      level: 0,
      id: null,
      isRoot: false,
      quickReplies: [],
    });
  };
  return (
    <div className="adminsSectiondMainContainer">
      <div className="adminsListContainer">
        {posts &&
          posts.items &&
          posts.items.map((post) => (
            <PostsListItem
              key={post.id}
              displayPostData={displayPostData}
              postData={post}
              isSelected={selectedElement === post.id}
            />
          ))}
        <Pagination
          totalPages={posts && posts.meta ? posts.meta.totalPages : 1}
          page_number={pageNumber}
          onChangePageNumber={onChangePageNumber}
        />
        <div className="adminsSectionBtnContainer">
          <button onClick={openPostCreationForm} className="Btn">
            +
          </button>
        </div>
      </div>
      <div className="adminsFormContainer">
        {conversationSectionState ? (
          <>
            <PostDisplay disableForm={disableForm} postData={postData} />
            {conversations && conversations.length > 0 && (
              <ConversationBlocs
                disableForm={disableForm}
                createBloc={createBloc}
                displayConversationData={displayConversationData}
                conversationsList={conversations}
                isSelected={selectedBloc}
              />
            )}
            <AddDiscution
              disableForm={disableForm}
              conversationsList={conversations}
              createBloc={createBloc}
              conversationData={conversationData}
              postData={postData}
            />
          </>
        ) : (
          <PostForm
            disableForm={disableForm}
            postData={postData}
            openConversationSection={openConversationSection}
            academicYears={academicYears}
          />
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  posts: state.posts.posts,
  conversations: state.conversations.questions,
  academicYears: state.academicYears.academicYears.items,
  loading: state.posts.loading,
});

PostsSection.propTypes = {
  getPostsList: PropTypes.func.isRequired,
  getAcademicYearList: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { getPostsList, getAcademicYearList })(
  PostsSection
);
