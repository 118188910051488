import {
  CREATE_ESTABLISHMENT,
  ESTABLISHMENT_ERROR,
  ESTABLISHMENTS_LIST,
  UPDATE_ESTABLISHMENT,
  FULL_ESTABLISHMENTS_LIST
} from "../actions/types";

const initialState = {
  loading: true,
  establishment: null,
  establishments: [],
  establishments_all: [],
  error: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CREATE_ESTABLISHMENT:
      return {
        ...state,
        establishments: {
          ...state.establishments,
          items: [payload,...state.establishments.items],
        },
        loading: false,
      };
    case ESTABLISHMENT_ERROR:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case ESTABLISHMENTS_LIST: {
      return {
        ...state,
        establishments: payload,
        loading: false,
      };
    }
    case FULL_ESTABLISHMENTS_LIST: {
      return {
        ...state,
        establishments_all: payload,
        loading: false,
      };
    }
    case UPDATE_ESTABLISHMENT: {
      return {
        ...state,
        establishments: {
          ...state.establishments,
          items: state.establishments.items.map((establishment) =>
            establishment.id === payload.id ? payload : establishment
          ),
        },
        loading: false,
      };
    }
    default:
      return state;
  }
}
