import React from "react";
import moment from "moment";
import QuestionBox from "../../assets/images/question-box.png";
const QuestionBoxListItem = ({
  displayQuestionData,
  questionData,
  isSelected,
}) => {
  const age = moment().diff(questionData.askedBy.dateOfBirth, "years");
  return (
    <div
      onClick={() => {
        displayQuestionData(questionData);
      }}
      className={
        isSelected
          ? "adminListitemMainContainer-active"
          : "adminListitemMainContainer"
      }
    >
      <img src={QuestionBox} alt="QuestionBox" />
      <div
        style={{
          width: "20rem",
        }}
        className="adminListitemTextContainer"
      >
        <p className="questionBoxItemMainText">
          De {questionData.askedBy.firstName}, {age} ans
        </p>
        <p className="questionBoxItemDateText">
          Reçu le{" "}
          {moment(questionData.createdAt).utc().format("DD-MM-YYYY HH:mm")}{" "}
        </p>
        <p className="questionBoxItemSubjectText">{questionData.question}</p>
      </div>
      {questionData.isSeen ? (
        <button className="Btn">Traité</button>
      ) : (
        <button
          style={{
            background: "#28A751",
          }}
          className="Btn"
        >
          Non traité
        </button>
      )}
    </div>
  );
};

export default QuestionBoxListItem;
