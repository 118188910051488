import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import StatsMonthsListItem from "./StatsMonthsListItem";
import { getCurrentYear } from "../../utils/moment";
const StatsMonthsList = ({ establishmentData, displayStatsTable }) => {

  const actualYear = new Date().getFullYear();
  const actualMonth = new Date().getMonth();
  const years = () => {
    const back = actualYear - 2021;
    return Array.from({ length: back }, (v, i) => 2022 + i);
  };
  const [selectedYear, setSelectedYear] = useState(getCurrentYear());

  const [yearsArray, setYearsArray] = useState([getCurrentYear()]);
  const monthsToDisplay = selectedYear == actualYear ? actualMonth + 1 : 12;
  const monthsArray = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];

  useEffect(() => {
    setYearsArray(years());
  }, [setYearsArray]);

  const onChange = (e) => {
    setSelectedYear(e.target.value);
  };

  return (
    <>
      <p className="addAdminFormTitle">Statistiques mensuelles</p>
      <select
        style={{
          width: "40%",
        }}
        onChange={(e) => onChange(e)}
        value={selectedYear}
        name="year"
      >
        {yearsArray.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
        {monthsArray.slice(0, monthsToDisplay).map((month,index) => (
          <StatsMonthsListItem key={month} displayStatsTable={displayStatsTable} month={month} index={index} selectedYear={selectedYear} />
        ))} 
    </>
  );
};

export default connect(null, {})(StatsMonthsList);
