import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { inviteAdmin, updateAdmin } from "../../actions/admins";
import validateEmail from "../../utils/validateMail";
const AdminForm = ({ adminData, inviteAdmin, updateAdmin }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    isArchived: false,
    id: null,
  });
  const [error, setError] = useState(null);
  useEffect(() => {
    setFormData({
      firstName: adminData ? adminData.firstName : "",
      lastName: adminData ? adminData.lastName : "",
      email: adminData ? adminData.email : "",
      isArchived: adminData ? adminData.isArchived : false,
      id: adminData ? adminData.id : null,
    });
  }, [adminData]);

  const { id, email, firstName, lastName, isArchived } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setError(null);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (firstName.length === 0) {
      setError("Le Prenom est obligatoire");
      return;
    }
    if (lastName.length === 0) {
      setError("Le nom est obligatoire");
      return;
    }
    const validateMail = validateEmail(email);
    if (!validateMail) {
      setError("Format e-mail invalide");
      return;
    }
    if (id) {
      updateAdmin(formData);
    } else {
      inviteAdmin(formData);
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        id: null,
      });
    }
  };

  const submitArchiveAdmin = (e) => {
    e.preventDefault();
    updateAdmin({ id, isArchived: !isArchived });
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      isArchived: !isArchived,
      id: null,
    });
  };

  return (
    <>
      <p className="addAdminFormTitle">
        {id ? "administrateur" : "Nouvel administrateur"}
      </p>
      <div className="formSection">
        <input
          type="text"
          className="form-control"
          name="firstName"
          placeholder="Prénom"
          value={firstName}
          onChange={(e) => onChange(e)}
        />
        <input
          type="text"
          className="form-control"
          name="lastName"
          placeholder="Nom"
          value={lastName}
          onChange={(e) => onChange(e)}
        />
        <input
          type="email"
          className="form-control"
          name="email"
          placeholder="Email"
          value={email}
          onChange={(e) => onChange(e)}
        />
        <p className="error-msg">{error}</p>
      </div>
      <div className="adminsFormBtnContainer">
        {id ? (
          <>
            <button onClick={submitArchiveAdmin} className="Btn returnBtn">
              Archiver
            </button>
            <button onClick={onSubmit} className="Btn">
              Modifier
            </button>
          </>
        ) : (
          <button onClick={onSubmit} className="Btn">
            Envoyer l'invitation
          </button>
        )}
      </div>
    </>
  );
};

AdminForm.propTypes = {
  inviteAdmin: PropTypes.func.isRequired,
  updateAdmin: PropTypes.func.isRequired,
};

export default connect(null, { inviteAdmin, updateAdmin })(AdminForm);
