import React from "react";
import { Route, Switch } from "react-router-dom";
import Alert from "../components/layout/Alert";

import Login from "../components/auth/Login";
import ForgottenPassword from "../components/auth/ForgottenPassword";
import ResetPassword from "../components/auth/ResetPassword";
import SetPassword from "../components/auth/SetPassword";
import Dashboard from "../components/dashboard";
import AdminsSection from "../components/adminsSection";
import EstablishmentSection from "../components/establishmentSection";
import ProgramSection from "../components/programSection";
import StoriesSection from "../components/storiesSection";
import AcademicYearsSection from "../components/academicYearsSection";
import StudentsSection from "../components/studentsSection";
import StatisticsSection from "../components/statisticsSection";
import QuestionsBoxSection from "../components/questionsBoxSection";

import NotFound from "../components/notFound";
import PrivateRoute from "./PrivateRoute";
const Routes = () => {
  return (
    <>
      <Alert />
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/forgotten-password" component={ForgottenPassword} />
        <Route exact path="/signup" component={SetPassword} />
        <Route exact path="/reset-password" component={ResetPassword} />
        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        <PrivateRoute exact path="/admins" component={AdminsSection} />
        <PrivateRoute
          exact
          path="/establishment"
          component={EstablishmentSection}
        />
        <PrivateRoute exact path="/program" component={ProgramSection} />
        <PrivateRoute exact path="/stories" component={StoriesSection} />
        <PrivateRoute exact path="/students" component={StudentsSection} />
        <PrivateRoute exact path="/question-box" component={QuestionsBoxSection} />
        <PrivateRoute exact path="/statistics" component={StatisticsSection} />
        <PrivateRoute
          exact
          path="/academic-year"
          component={AcademicYearsSection}
        />
        <Route component={NotFound} />
      </Switch>
    </>
  );
};

export default Routes;
