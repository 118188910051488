import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { updateStudent } from "../../actions/student";
const AdminForm = ({ studentData, updateStudent }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    dateOfBirth: "",
    establishment: "",
    isActive: true,
    isArchived: false,
    id: null,
  });
  useEffect(() => {
    setFormData({
      firstName: studentData ? studentData.firstName : "",
      lastName: studentData ? studentData.lastName : "",
      dateOfBirth: studentData ? studentData.dateOfBirth : "",
      establishment: studentData ? studentData.establishment : "",
      email: studentData ? studentData.email : "",
      isArchived: studentData ? studentData.isArchived : false,
      isActive: studentData ? studentData.isActive : false,
      id: studentData ? studentData.id : null,
    });
  }, [studentData]);

  const {
    id,
    email,
    firstName,
    lastName,
    isActive,
    establishment,
    dateOfBirth,
  } = formData;

  const submitSuspendStudent = (e) => {
    e.preventDefault();
    updateStudent({ id, isActive: !isActive }, "suspending");
  };

  const submitArchiveStudent = (e) => {
    e.preventDefault();
    updateStudent({ id, isArchived: true }, "archiving");
  };

  return (
    <>
      <p className="addAdminFormTitle">{firstName + " " + lastName}</p>
      <div className="formSection">
        <input
          disabled
          type="text"
          className="form-control"
          name="firstName"
          placeholder="Prénom"
          value={firstName}
        />
        <input
          disabled
          type="text"
          className="form-control"
          name="lastName"
          placeholder="Nom"
          value={lastName}
        />
        <input
          disabled
          type="text"
          className="form-control"
          name="birthdate"
          placeholder="date de naissance"
          value={dateOfBirth}
        />
        <input
          disabled
          type="email"
          className="form-control"
          name="email"
          placeholder="Email"
          value={email}
        />
        <input
          disabled
          type="text"
          className="form-control"
          name="establishment"
          placeholder="établissement"
          value={establishment}
        />
      </div>
      <div className="adminsFormBtnContainer">
        <button onClick={submitArchiveStudent} className="Btn returnBtn">
          Archiver
        </button>
        <button onClick={submitSuspendStudent} className="Btn">
          {isActive ? "Suspendre" : "Reactiver"}
        </button>
      </div>
    </>
  );
};

AdminForm.propTypes = {
  updateStudent: PropTypes.func.isRequired,
};

export default connect(null, { updateStudent })(AdminForm);
