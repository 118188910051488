import axios from "axios";

import {
  CREATE_QUESTION,
  UPDATE_QUESTION,
  DELETE_QUESTION,
  CREATE_QR,
  CONVERSATION_ERROR,
  QUESTIONS_LIST,
  QUESTION_UPLOAD_FILE,
  CLEAR_UPLOAD_FILE,
} from "./types";
import { setAlert } from "./alert";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

//File Upload
export const questionUploadFiles = (file) => async (dispatch) => {
  let formData = new FormData();
  formData.append("file", file);
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };

  try {
    const res = await axios.post(`${BASE_URL}/upload`, formData, config);
    dispatch({
      type: QUESTION_UPLOAD_FILE,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: CONVERSATION_ERROR,
      payload: err.response.data.message,
    });
    dispatch(
      setAlert(`Echec de l'envoi: ${err.response.data.message}`, "danger")
    );
  }
};

// delete question
export const deleteQuestion = (questionId) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    await axios.delete(
      `${BASE_URL}/chat/posts/questions/${questionId}`,
      config
    );

    dispatch({
      type: DELETE_QUESTION,
      payload: questionId,
    });
    dispatch(setAlert("bloc supprimer", "success"));
  } catch (err) {
    dispatch({
      type: CONVERSATION_ERROR,
      payload: err.response.data.message,
    });
    dispatch(
      setAlert(`Echec de l'envoi: ${err.response.data.message}`, "danger")
    );
  }
};

// add question
export const createQuestion =
  (formData, postId, quickReplies) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    delete formData.isRoot;
    delete formData.level;
    const body = JSON.stringify(formData);
    try {
      const res = await axios.post(
        `${BASE_URL}/chat/posts/${postId}/questions/${
          formData.id ? `?questionId=${formData.id}` : ""
        }`,
        body,
        config
      );
      if (formData.id) {
        dispatch({
          type: UPDATE_QUESTION,
          payload: res.data,
        });
      } else {
        dispatch({
          type: CREATE_QUESTION,
          payload: res.data,
        });
      }
      dispatch(createQuickReply(quickReplies, res.data.id));
      dispatch({
        type: CLEAR_UPLOAD_FILE,
      });
      dispatch(setAlert("bloc discussion crée", "success"));
    } catch (err) {
      dispatch({
        type: CONVERSATION_ERROR,
        payload: err.response.data.message,
      });
      dispatch(
        setAlert(`Echec de l'envoi: ${err.response.data.message}`, "danger")
      );
    }
  };

// add quick reply
export const createQuickReply = (formData, questionId) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const body = JSON.stringify({ quickReplies: formData });
  try {
    const res = await axios.post(
      `${BASE_URL}/chat/posts/questions/${questionId}/quick-replies`,
      body,
      config
    );
    dispatch({
      type: CREATE_QR,
      payload: res.data,
    });
    dispatch({
      type: UPDATE_QUESTION,
      payload: res.data,
    });
    dispatch(setAlert("quick reply crée", "success"));
  } catch (err) {
    dispatch({
      type: CONVERSATION_ERROR,
      payload: err.response.data.message,
    });
    dispatch(
      setAlert(`Echec de l'envoi: ${err.response.data.message}`, "danger")
    );
  }
};

//Get conversations List
export const getConversationList = (postId) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(
      `${BASE_URL}/chat/posts/${postId}/questions`,
      config
    );

    dispatch({
      type: QUESTIONS_LIST,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: CONVERSATION_ERROR,
      payload: err.response.data.message,
    });
    dispatch(
      setAlert(`Echec de l'envoi: ${err.response.data.message}`, "danger")
    );
  }
};
