import React from "react";
import SpeachLogo from "../../assets/images/avatarSpeach.svg";
import BiologicIcon from "../../assets/images/bio-sujet@2x.png";
import SocialIcon from "../../assets/images/social-sujet@2x.png";
import PsychoIcon from "../../assets/images/psycho-sujet@2x.png";
import SponsorIcon from "../../assets/images/sponsor-sujet@2x.png";
import IntroIcon from "../../assets/images/intro-sujet@2x.png"

const PostDisplay = ({ postData, disableForm }) => {
  return (
    <div className="PostDetailsContainer">
      <div className="postDisplayItemContainer">
        <div className="postDisplayData">
          <img className="simulatorListitemImage" src={SpeachLogo} alt="admin" />
          <div className="adminListitemTextContainer">
            <p className="adminListitemName">Discussion associée {disableForm && "(Poste déjà publié)"} </p>
            <div className="programListitemContentContainer">
              <p
                className="programListitemText"
                style={{
                  fontSize: "1.4rem",
                }}
              >
                {postData.content}
              </p>
            </div>
          </div>
        </div>
        <div className="programListitemTypeContainer">
          <p className="programListitemTypeText">
            {postData.type === "bio"
              ? "Biologique"
              : postData.type === "psycho"
              ? "Psycho-affectif"
              : postData.type === "social"
              ? "Social"
              : postData.type === "sponsor"
              ? "Sponsorisé"
              : "Présentation"}
          </p>
          <img
            className="programListitemImage"
            src={
              postData.type === "bio"
                ? BiologicIcon
                : postData.type === "psycho"
                ? PsychoIcon
                : postData.type === "social"
                ? SocialIcon
                : postData.type === "sponsor"
                ? SponsorIcon
                : IntroIcon
            }
            alt="admin"
          />
        </div>
      </div>
    </div>
  );
};
export default PostDisplay;
