import axios from "axios";
import {
  INVITE_ADMIN,
  ADMIN_ERROR,
  ADMINS_LIST,
  UPDATE_ADMIN,
  ARCHIVE_ADMIN,
} from "./types";
import { setAlert } from "./alert";

const BASE_URL = process.env.REACT_APP_SERVER_URL;

// Invite Admin
export const inviteAdmin = (formData) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify(formData);

  try {
    const res = await axios.post(
      `${BASE_URL}/users/invite-admin`,
      body,
      config
    );

    dispatch({
      type: INVITE_ADMIN,
      payload: res.data,
    });
    dispatch(setAlert("Invitation envoyée", "success"));
  } catch (err) {
    dispatch({
      type: ADMIN_ERROR,
      payload: err.response.data.message,
    });
    dispatch(
      setAlert(`Echec de l'envoi: ${err.response.data.message}`, "danger")
    );
  }
};

//Get admins List
export const getAdminsList = (page, limit) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  try {
    const res = await axios.get(
      `${BASE_URL}/users/?role=admin&page=${page}&limit=${limit}`,
      config
    );

    dispatch({
      type: ADMINS_LIST,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: ADMIN_ERROR,
      payload: err.response.data.message,
    });
    dispatch(
      setAlert(`Echec de l'envoi: ${err.response.data.message}`, "danger")
    );
  }
};

//update admin

export const updateAdmin = (formData) => async (dispatch) => {
  const config = {
    headers: {
      "app-domain": "backoffice",
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify(formData);

  try {
    const res = await axios.patch(
      `${BASE_URL}/users/${formData.id}`,
      body,
      config
    );
    if (formData.isArchived) {
      dispatch({
        type: ARCHIVE_ADMIN,
        payload: formData.id,
      });
    } else {
      dispatch({
        type: UPDATE_ADMIN,
        payload: res.data,
      });
    }
    dispatch(setAlert("l'admin à été mis à jour", "success"));
  } catch (err) {
    dispatch({
      type: ADMIN_ERROR,
      payload: err.response.data.message,
    });
    dispatch(
      setAlert(`Echec de l'envoi: ${err.response.data.message}`, "danger")
    );
  }
};
