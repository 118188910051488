import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import Routes from "./routing/Routes";
import { createBrowserHistory } from "history";
import "./App.scss";
import ErrorBoundary from "./utils/errorBoundary";
import Navbar from "./components/navBar";
// Redux
import { Provider } from "react-redux";
import store from "./store";

import { loadUser } from "./actions/auth";

const history = createBrowserHistory();

const App = () => {
  store.dispatch(loadUser());
  return (
    <Provider store={store}>
      <Router history={history}>
        <ErrorBoundary history={history}>
          <Navbar />
          <Switch>
            <Route component={Routes} />
          </Switch>
        </ErrorBoundary>
      </Router>
    </Provider>
  );
};

export default App;
